import { Component } from '@angular/core';
import { DialogRef, DialogResult, DialogService } from '@trumpf/xguide';
import { map } from 'rxjs/operators';
import { Storable } from '../../../shared/data-persistence/types';
import { TranslationHelper } from '../../../shared/helpers';
import { UiElementIds } from '../../../shared/usage-tracking/ui-element-ids';
import { ConfiguratorDataAccessService } from '../../services';
import { ConfigurationWizardInfo, MachineConfiguration } from '../../types';
import { copyOfMachine } from '../../utility';
import { ConfigurationWizardComponent } from '../configuration-wizard/configuration-wizard.component';
import { CreateConfigurationDialogViewComponent } from '../create-configuration-dialog-view/create-configuration-dialog-view.component';

@Component({
  selector: 'lsb-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
})
export class ConfiguratorComponent {
  public readonly uiElementIds = UiElementIds;
  public readonly machineConfigurations$ = this.configurationService.data$;
  public readonly hasMachineConfigurations$ = this.machineConfigurations$.pipe(
    map((data) => data.length > 0),
  );

  private wizardRef: DialogRef<any>;

  constructor(
    public translations: TranslationHelper,
    private configurationService: ConfiguratorDataAccessService,
    private dialogService: DialogService,
  ) {}

  ngOnDestroy() {
    this.wizardRef?.close({ result: DialogResult.Cancel });
  }

  public async showChooseMachineWizard() {
    this.wizardRef = this.dialogService.openWithRef(
      CreateConfigurationDialogViewComponent,
      undefined,
    );

    const result = await this.wizardRef.result;

    if (result.result === DialogResult.Ok) {
      return this.createConfigurationForMachine(result.payload);
    }
  }

  public async createConfigurationForMachine(configuration?: MachineConfiguration) {
    return configuration == null
      ? this.startConfigurationWizard()
      : this.addConfiguration(configuration);
  }

  public startConfigurationWizard() {
    const machineTemplate = this.configurationService.createEmpty('TruLaser Weld 5000', 'laser');
    return this.openWizard(machineTemplate, true);
  }

  public async addConfiguration(configuration: Storable<MachineConfiguration>) {
    const configWithId = {
      ...configuration,
      id: this.configurationService.getNextId(),
    };

    return this.configurationService.create(configWithId);
  }

  public removeConfiguration(configId: string) {
    this.configurationService.delete(configId);
  }

  public async editConfiguration(configurationId: string) {
    const machineToEdit = await this.configurationService.read(configurationId);

    /*
      hint: langju:
      copy machine config to decouple the already existing configuration object
      that is stored in service, from the one being edited in wizard. This avoids
      changes from wizard "bleeding" into the original object, as the wizard should
      keep all changes separated from original UNTIL the user clicks "finish" button.
      Only then, the changes should be applied to the original configuration object.
    */
    const copy = copyOfMachine(machineToEdit);
    this.openWizard(copy, false);
  }

  public downloadCad(configurationId: string) {
    console.log('cad download for ', configurationId);
  }

  private async openWizard(
    machineTemplate: MachineConfiguration,
    setComponentOptionsInitialState: boolean,
  ) {
    const configurationWizardInfo: ConfigurationWizardInfo = {
      configuration: machineTemplate,
      setComponentOptionsInitialState,
    };

    this.wizardRef = this.dialogService.openWithRef(ConfigurationWizardComponent, {
      ...configurationWizardInfo,
    });

    const wizardResult = await this.wizardRef.result;

    if (wizardResult.result === DialogResult.Ok) {
      // langju: fragile code. expects wizard to throw if payload is undefined.
      this.configurationService.createOrUpdate(wizardResult.payload!);
    }
  }
}
