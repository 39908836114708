<ng-container *ngIf="currentRoute$ | async as currentRoute">
  <xui-sub-menu>
    <xui-sub-menu-tab
      xuiClickUsageTracking="{{ uiElementIds.IMPRINT }}"
      [selected]="currentRoute.endsWith('imprint')"
      [label]="translations.ABOUT.IMPRINT | translate"
      routerLink="/about/imprint"
    ></xui-sub-menu-tab>
    <xui-sub-menu-tab
      xuiClickUsageTracking="{{ uiElementIds.PRIVACY }}"
      [selected]="currentRoute.endsWith('privacy')"
      [label]="translations.ABOUT.DATA_PRIVACY | translate"
      routerLink="/about/privacy"
    ></xui-sub-menu-tab>
    <xui-sub-menu-tab
      xuiClickUsageTracking="{{ uiElementIds.COPYRIGHT }}"
      [selected]="currentRoute.endsWith('copyright')"
      [label]="translations.ABOUT.COPYRIGHT | translate"
      routerLink="/about/copyright"
    ></xui-sub-menu-tab>
    <xui-sub-menu-tab
      xuiClickUsageTracking="{{ uiElementIds.SETTINGS }}"
      [selected]="currentRoute.endsWith('settings')"
      [label]="translations.ABOUT.SETTINGS | translate"
      routerLink="/about/settings"
    ></xui-sub-menu-tab>
  </xui-sub-menu>
</ng-container>

<div class="content">
  <router-outlet></router-outlet>
</div>
