import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SettingsAccessor, SettingsService } from '@trumpf/xguide';
import { DEFAULT_APP_SETTINGS, SHARED_SETTINGS_NAMESPACE } from '../../../constants';
import {
  AppSettings,
  ImperialRateUnit,
  ImperialUnit,
  MetricRateUnit,
  MetricUnit,
  SystemOfUnits,
  UnitConverter,
  ValueUnit,
} from '../../../types';
import { ConvertComponent } from '../../components/convert/convert.component';
import { ValueComponent } from '../../components/value/value.component';
import { ConversionDirection } from '../../types';

/**
 * A length-converter that works with the `<lsb-value>` component.
 *
 * ---
 *
 * ~~~html
 * <lsb-value #seamLengthInCentimeterOrInch [binding]="seamLengthInMillimeters">
 *   <lsb-length-converter metricTargetUnit="cm" imperialTargetUnit="inch"></lsb-length-converter>
 * </lsb-value>
 *
 * <p>Seam length: {{ seamLengthInCentimeterOrInch.value }} {{ seamLengthInCentimeterOrInch.unit }}.</p>
 * ~~~
 * ---
 * **Please note:** This converter is dependent on the current app-settings.
 *
 * When the user setting for the unit-system is set to `SystemOfUnits.Imperial`, this converter
 * will use the `imperialTargetUnit` as conversion target.
 *
 * If the unit-system setting is set to `SystemOfUnits.Metric`, the `metricTargetUnit` is used as conversion
 * target.
 *
 * **This setting-dependency is also why using the `targetUnit` binding will throw an error.**.
 * Please always use the both input-properties `metricTargetUnit` and `imperialTargetUnit` as shown
 * in the example above.
 *
 * ---
 * **Please note:** This converter will auto-update the `unit` property of its parent `lsb-value` component
 * to the current active conversion-target unit. This way you can easily bind to the `lsb-value`'s unit as
 * shown in the code example above.
 */
@Component({
  selector: 'lsb-length-converter',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LengthConverterComponent extends ConvertComponent {
  @Input() metricTargetUnit: MetricUnit | MetricRateUnit;
  @Input() imperialTargetUnit: ImperialUnit | ImperialRateUnit;

  public get unit(): ValueUnit {
    return this.systemOfUnits === SystemOfUnits.Metric
      ? this.metricTargetUnit
      : this.imperialTargetUnit;
  }

  private settings: SettingsAccessor<AppSettings>;
  private get systemOfUnits(): SystemOfUnits {
    return this.settings.get('unitSystem');
  }

  protected knownConverters = [...LengthConverters];

  constructor(value: ValueComponent<string>, settingsService: SettingsService) {
    super(value);

    this.settings = settingsService.access<AppSettings>(
      SHARED_SETTINGS_NAMESPACE,
      DEFAULT_APP_SETTINGS,
    );
  }

  ngOnChanges() {
    if (this.targetUnit) {
      throw new Error(
        `LengthConverter: Please use the "metricTargetUnit" and "imperialTargetUnit" @Inputs instead of "targetUnit".`,
      );
    }
  }

  protected getConverter(_: ValueUnit, direction: ConversionDirection) {
    const converter =
      this.systemOfUnits === SystemOfUnits.Metric
        ? this.knownConverters.find((c) => c.to === this.metricTargetUnit)
        : this.knownConverters.find((c) => c.to === this.imperialTargetUnit);

    return direction === 'to-view' ? converter?.convert : converter?.convertBack;
  }
}

// -----------------------------------------
// ---------- Module Internals -------------
// -----------------------------------------

/* 1 inch = 2.54 cm */
export const cmInchFactor = 2.54;

const LengthConverters: UnitConverter[] = [
  // Length
  {
    from: 'mm',
    to: 'mm',
    convert: (mm) => mm,
    convertBack: (mm) => mm,
  },
  {
    from: 'mm',
    to: 'cm',
    convert: (mm) => mm / 10,
    convertBack: (cm) => cm * 10,
  },
  {
    from: 'mm',
    to: 'inch',
    convert: (mm) => mm / 10 / cmInchFactor,
    convertBack: (inch) => inch * cmInchFactor * 10,
  },
  // LengthRate
  {
    from: 'mm/s',
    to: 'mm/s',
    convert: (mmPerSec) => mmPerSec,
    convertBack: (mmPerSec) => mmPerSec,
  },
  {
    from: 'mm/s',
    to: 'm/min',
    convert: (mmPerSec) => (mmPerSec / 1000) * 60,
    convertBack: (mPerMin) => (mPerMin * 1000) / 60,
  },
  {
    from: 'mm/s',
    to: 'inch/min',
    convert: (mmPerSec) => (mmPerSec / 10 / cmInchFactor) * 60,
    convertBack: (inchPerMin) => (inchPerMin * cmInchFactor * 10) / 60,
  },
];
