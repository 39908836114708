<xui-wizard
  [tabs]="tabs"
  [translateTabs]="true"
  [canFinish]="this.selectedTab.label === TAB_LABEL_3"
  [ngSwitch]="selectedTab?.label"
  (selectedTabChange)="onTabChange($event)"
  (finishWizard)="finishDialog($event)"
>
  <lsb-robot-safety-cabin
    *ngSwitchCase="TAB_LABEL_1"
    [(configuration)]="configuration"
    [layoutModels]="layoutModels"
    (cabinLayoutChange)="onSelectedCabinChange($event)"
  ></lsb-robot-safety-cabin>

  <lsb-configuration-wizard-step-two
    *ngSwitchCase="TAB_LABEL_2"
    [(configurationWizardInfo)]="configurationWizardInfo"
  ></lsb-configuration-wizard-step-two>

  <lsb-optic-configuration
    *ngSwitchCase="TAB_LABEL_3"
    [(configuration)]="configuration"
    (navigateTo)="onNavigateTo($event)"
  ></lsb-optic-configuration>
</xui-wizard>
