import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { LocalStorageService } from '@trumpf/xguide';

export const REFERRER = 'referrer';

// https://en.wikipedia.org/wiki/HTTP_referer -> `In HTTP, "Referer" (a misspelling of Referrer[1]) ...`
const HTTP_REFERER_SPELLING = 'referer';

@Injectable({
  providedIn: 'root',
})
export class ReferrerTrackingService {
  constructor(private localStorage: LocalStorageService) {}

  /**
   * Method parses incoming URL. If URL contains parameter 'Link', method stores the link in the local storage
   */
  public collectUrlTrackingParameter(queryParamMap?: ParamMap) {
    if (!queryParamMap) {
      return;
    }

    const usedReferrerParameterKey =
      queryParamMap.keys.find(
        (key) =>
          key.toLowerCase() === REFERRER.toLocaleLowerCase() ||
          key.toLocaleLowerCase() === HTTP_REFERER_SPELLING.toLocaleLowerCase(),
      ) ?? REFERRER;

    const urlTrackingParameter = queryParamMap.get(usedReferrerParameterKey);

    if (urlTrackingParameter) {
      // Url contains tracking parameter.
      const existingParameter = this.localStorage.get(REFERRER);
      if (!existingParameter || existingParameter !== urlTrackingParameter) {
        this.localStorage.set(REFERRER, urlTrackingParameter);
      }
    }
  }

  public getReferrer(): string | undefined {
    const existedLinkParameter = this.localStorage.get(REFERRER);
    if (
      existedLinkParameter === undefined ||
      existedLinkParameter === null ||
      existedLinkParameter === ''
    ) {
      return undefined;
    }
    return existedLinkParameter;
  }
}
