import { Component } from '@angular/core';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';

@Component({
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  constructor(public translations: TranslationHelper) {}
}
