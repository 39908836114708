<div class="output-text h1">
  <span class="output-headline">{{ translations.ABOUT.IMPRINT | translate }}</span>
</div>

<div class="output-text h2">
  <span class="output-headline subtitle">{{ translations.IMPRINT.OPERATOR | translate }}</span>
</div>

<div class="output copytext">
  <span class="output-content emphasis">TRUMPF Werkzeugmaschinen SE + Co. KG</span>
</div>

<p class="address">
  Johann-Maus-Straße 2<br />
  71254 Ditzingen
</p>

<div class="contact">
  <div class="icon-text">
    <xui-icon iconKey="Phone" color="#285172"></xui-icon>
    <span>+49 7156 303-0</span>
  </div>
  <div class="icon-text">
    <xui-icon iconKey="Phone" color="#285172"></xui-icon>
    <span>+49 7156 303-930309</span>
  </div>
  <div class="icon-text separated">
    <xui-icon iconKey="Envelope" color="#285172"></xui-icon>
    <span>info@trumpf.com</span>
  </div>
</div>

<div class="additional-info output copytext">
  <h3>{{ translations.IMPRINT.MANAGEMENT | translate }}</h3>
  <p class="output-content">
    Dr.-Ing. Stephan Mayer <br />
    Dr.-Ing. Thomas Schneider
  </p>
</div>

<div class="additional-info output copytext">
  <h3>{{ translations.IMPRINT.REGISTER_COURT | translate }}</h3>
  <p class="output-content">
    Amtsgericht Stuttgart HRA 202772 <br />
    USt-Id-Nr. DE 813 103 015 <br />
    PhG TRUMPF Werkzeugmaschinen <br />
    Beteiligungs-GmbH, Sitz Ditzingen
    <br />
    <br />
    Amtsgericht Stuttgart HRB 206070
  </p>
</div>
