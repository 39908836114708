import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ITransform } from '../../types';
import { TransformComponent } from '../transform/transform.component';

/**
 * Template component for creating custom, formatting transform-components that work with the `lsb-value` component.
 * In order to use this component just extend from it and implement the abstract methods.
 */
@Component({
  selector: 'lsb-format',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FormatComponent extends TransformComponent implements ITransform<string> {
  abstract transform(value: string | undefined): string | undefined;

  transformBack(value: string | undefined): string | undefined {
    const formatted = this.transform(value);

    if (formatted && formatted !== value) {
      this.value.forceUpdateView(formatted);
    }

    return formatted;
  }
}
