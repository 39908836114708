<div *ngIf="flags.enablePreviewFeatures$ | async" class="stacked preview">
  <lsb-cad-model-drop-zone
    class="model-drop-zone"
    [class.fullscreen]="showModelFullScreen"
    [(modelDataUri)]="basics.cadModelDataUri"
    (modelDataUriChange)="notifyUpdate()"
    (modelNameChange)="nameChange.emit($event)"
    (previewImageChange)="updatePreview($event)"
    (modelClick)="toggleFullScreen()"
    [toolbar]="showModelFullScreen"
    rotatable
    zoomable
  >
    {{ translations.CALCULATOR.DROP_STEP_FILE_HINT | translate }}
  </lsb-cad-model-drop-zone>
  <p class="model-hint" [class.fullscreen]="showModelFullScreen" (click)="toggleFullScreen()">
    {{
      (showModelFullScreen
        ? translations.CALCULATOR.WIZARD.TAB_GENERAL.MODEL_FULLSCREEN_HINT
        : translations.CALCULATOR.WIZARD.TAB_GENERAL.MODEL_PREVIEW_HINT
      ) | translate
    }}
  </p>
</div>

<div class="stacked">
  <div class="row">
    <xui-input-field
      class="name-field"
      data-ngtx="basics-tab:name"
      [label]="translations.CALCULATOR.WIZARD.TAB_GENERAL.NAME | translate"
      [(text)]="nameValue.value"
    >
      <lsb-value
        #nameValue
        lsbValidateRequired
        [(binding)]="name"
        [fallbackValue]="name"
        (bindingChange)="nameChange.emit($event)"
      >
      </lsb-value>
    </xui-input-field>

    <lsb-input-field
      data-ngtx="basics-tab:total-seam-length"
      [label]="translations.CALCULATOR.WIZARD.TAB_GENERAL.TOTAL_SEAM_LENGTH | translate"
      [unit]="totalSeamLengthValue.unit"
      [valueField]="true"
      [(text)]="totalSeamLengthValue.value"
    >
      <lsb-value
        #totalSeamLengthValue
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [binding]="basics?.totalSeamLength"
        [fallbackValue]="basics?.totalSeamLength"
        (bindingChange)="update('totalSeamLength', $event)"
      >
        <lsb-length-converter
          metricTargetUnit="mm"
          imperialTargetUnit="inch"
        ></lsb-length-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <xui-icon
        class="info-flag total-seam-length"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="totalSeamLengthTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #totalSeamLengthTooltip>
        <p class="simple-text-tooltip total-seam-length">
          {{ translations.CALCULATOR.WIZARD.TAB_GENERAL.TOOLTIPS.TOTAL_SEAM_LENGTH | translate }}
        </p>
      </xui-tooltip>
    </lsb-input-field>
  </div>

  <div class="row">
    <xui-input-field
      data-ngtx="basics-tab:total-pieces"
      [label]="translations.CALCULATOR.WIZARD.TAB_GENERAL.TOTAL_PIECES | translate"
      [(text)]="totalPiecesValue.value"
    >
      <lsb-value
        #totalPiecesValue
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [binding]="basics?.totalPieces"
        [fallbackValue]="basics?.totalPieces"
        (bindingChange)="update('totalPieces', $event)"
      >
        <lsb-number-formatter [maxDecimals]="0"></lsb-number-formatter>
      </lsb-value>

      <xui-icon
        class="info-flag total-pieces-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="totalPiecesTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #totalPiecesTooltip>
        <p class="simple-text-tooltip total-pieces-tooltip">
          {{ translations.CALCULATOR.WIZARD.TAB_GENERAL.TOOLTIPS.TOTAL_PIECES | translate }}
        </p>
      </xui-tooltip>
    </xui-input-field>

    <xui-input-field
      data-ngtx="basics-tab:amount-seams"
      [label]="translations.CALCULATOR.WIZARD.TAB_GENERAL.AMOUNT_SEAMS | translate"
      [(text)]="amountSeamsValue.value"
    >
      <lsb-value
        #amountSeamsValue
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [binding]="basics?.amountSeams"
        [fallbackValue]="basics?.amountSeams"
        (bindingChange)="update('amountSeams', $event)"
      >
        <lsb-number-formatter [maxDecimals]="0"></lsb-number-formatter>
      </lsb-value>

      <xui-icon
        class="info-flag amount-seams-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="amountSeamsTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #amountSeamsTooltip>
        <p class="simple-text-tooltip amount-seams-tooltip">
          {{ translations.CALCULATOR.WIZARD.TAB_GENERAL.TOOLTIPS.AMOUNT_SEAMS | translate }}
        </p>
      </xui-tooltip>
    </xui-input-field>
  </div>

  <div class="row">
    <xui-input-field
      data-ngtx="basics-tab:batch-size"
      [label]="translations.CALCULATOR.WIZARD.TAB_GENERAL.BATCH_SIZE | translate"
      [(text)]="batchSizeValue.value"
    >
      <lsb-value
        #batchSizeValue
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
        [binding]="basics?.batchSize"
        [fallbackValue]="basics?.batchSize"
        (bindingChange)="update('batchSize', $event)"
      >
        <lsb-number-formatter [maxDecimals]="0"></lsb-number-formatter>
      </lsb-value>

      <xui-icon
        class="info-flag batch-size-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="batchSizeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #batchSizeTooltip>
        <p class="simple-text-tooltip batch-size-tooltip">
          {{ translations.CALCULATOR.WIZARD.TAB_GENERAL.TOOLTIPS.BATCH_SIZE | translate }}
        </p>
      </xui-tooltip>
    </xui-input-field>
  </div>
</div>
