<div class="half-panel cabin-settings">
  <div class="cabin-layout">
    <div class="output copytext">
      <span class="output-content">
        {{ translations.CONFIGURATOR_WIZARD.ROBOT_SAFETY_CABIN_CONTENT.CABIN_LAYOUT | translate }}
      </span>
    </div>

    <xui-dropdown #dropDown class="drop-down" [flat]="true" [label]="selectedCabin?.cabinLayout"
    data-ngtx="robot-safety-cabin:cabin-layout">
      <xui-dropdown-item
        *ngFor="let cabin of cabins"
        (click)="selectCabin(cabin); dropDown.close()"
      >
        {{ cabin?.layout }}
      </xui-dropdown-item>
    </xui-dropdown>

    <xui-input-field
      [text]="selectedCabin?.cabinSize + ' mm'"
      [readonly]="true"
      [label]="translations.CONFIGURATOR_WIZARD.ROBOT_SAFETY_CABIN_CONTENT.CABIN_SIZE | translate"
      ></xui-input-field>

    <div class="output copytext robot-dropdown">
      <span class="output-content">
        {{ translations.CONFIGURATOR_WIZARD.ROBOT_SAFETY_CABIN_CONTENT.ROBOT_LABEL | translate }}
      </span>
    </div>
    <xui-dropdown
      #dropDownRobot
      class="drop-down"
      [flat]="true"
      [label]="selectedRobot"
      data-ngtx="robot-safety-cabin:robot"
    >
      <xui-dropdown-item
        *ngFor="let robot of robots"
        (click)="selectRobot(robot.type, dropDownRobot)"
      >
        {{ robot?.name }}
      </xui-dropdown-item>
    </xui-dropdown>

  </div>
</div>

<div class="half-panel image-preview">
  <img class="image non-draggable" [src]="cabinIllustrationUrl | imageUrl" />
</div>
