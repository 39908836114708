import { CabinRules } from './cabin-rules';
import { RULES_FOR_BIG_CABINS, setDefaultValuesForRulesForBigCabins } from './rules-for-big-cabins';
import {
  RULES_FOR_MIDDLE_SIZED_CABINS,
  setDefaultValuesForRulesForMiddleSizedCabins,
} from './rules-for-middle-sized-cabins.data';
import {
  RULES_FOR_SMALL_CABINS_WITH_LONG_BACK,
  setDefaultValuesForSmallCabinsWithLongBack,
} from './rules-for-small-cabins-with-long-back.data';
import {
  RULES_FOR_SMALL_CABINS_WITH_SHORT_BACK,
  setDefaultValuesForSmallCabinsWithShortBack,
} from './rules-for-small-cabins-with-short-back';

/** Factory method that chooses group of rules by cabin size */
export function getRulesByCabinLayout(cabinSize: string): CabinRules {
  switch (cabinSize) {
    case '4x5':
    case '5x5':
      return {
        rules: RULES_FOR_SMALL_CABINS_WITH_LONG_BACK,
        setDefaultValues: setDefaultValuesForSmallCabinsWithLongBack,
      };
    case '6x4':
    case '6x5':
      return {
        rules: RULES_FOR_MIDDLE_SIZED_CABINS,
        setDefaultValues: setDefaultValuesForRulesForMiddleSizedCabins,
      };
    case '7x4':
    case '7x5':
    case '8x4':
      return {
        rules: RULES_FOR_BIG_CABINS,
        setDefaultValues: setDefaultValuesForRulesForBigCabins,
      };
    case '4x4':
    case '5x4':
      return {
        rules: RULES_FOR_SMALL_CABINS_WITH_SHORT_BACK,
        setDefaultValues: setDefaultValuesForSmallCabinsWithShortBack,
      };
    default:
      throw new Error(`Not implemented: Cabin-rules for cabin-size "${cabinSize}" missing.`);
  }
}
