import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  DialogService,
  UsageTrackingPermissionService,
  UsageTrackingService,
} from '@trumpf/xguide';
import { Observable, Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { NavigationUsageTracking, UNKNOWN, UsageTrackingMessageType } from '../../../shared';
import { TranslationHelper } from './shared/helpers/translation.helpers';
import { IsSupportedBrowserToken } from './shared/injection-tokens/is-supported-browser.injection.token';
import { ReferrerTrackingService } from './shared/referrer-tracking/referrer-tracking.service';
import { AuthService } from './shared/services/auth/auth.service';
import { HmiService } from './shared/services/hmi/hmi.service';
import { LanguageService } from './shared/services/language/language.service';
import { UiElementIds } from './shared/usage-tracking/ui-element-ids';
import { UnsupportedBrowserComponent } from './views/unsupported-browser/unsupported-browser.component';

@Component({
  selector: 'lsb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly uiElementIds = UiElementIds;
  public isLoggedIn$: Observable<boolean>;
  public isDialogActive$ = this.dialog.isActive;
  public get currentLanguageCode(): string {
    const languageCode = this.languageService.currentLanguage.toString();
    return languageCode.slice(0, 2).toUpperCase();
  }

  private routerEvents = this.router.events.pipe(
    filter((routerEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
  );
  private subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private usageTrackingPermission: UsageTrackingPermissionService,
    private usageTrackingService: UsageTrackingService,
    private languageService: LanguageService,
    public translations: TranslationHelper,
    private dialog: DialogService,
    @Inject(IsSupportedBrowserToken) public isSupportedBrowser: boolean,
    public hmiService: HmiService,
    private urlTracking: ReferrerTrackingService,
  ) {
    this.initAuthentication();
    this.sendApplicationStartUsageTracking();
    this.initUsageTrackingService();
    this.initPrivacyPopup();
    this.languageService.initLanguage();
  }

  async ngOnInit() {
    if (!this.isSupportedBrowser) {
      await this.dialog.open(UnsupportedBrowserComponent);
    }
  }

  private initAuthentication() {
    this.isLoggedIn$ = this.authService.isAuthenticated$;

    this.authService.runInitialLoginSequence().catch((e) => console.log(e));
  }

  private initPrivacyPopup() {
    const privacyPopupUrlSubscription = this.routerEvents.subscribe((event) => {
      const url = event.urlAfterRedirects;
      this.usageTrackingPermission.closePrivacySettingsPopup();

      if (url.includes('/about/')) {
        // closing the popup(s) to let the user see the /about/* pages, incl. settings, privacy, etc.
        this.usageTrackingPermission.closeUserPermissionPopup();
        return;
      }

      if (!this.usageTrackingPermission.userAlreadyAsked) {
        this.usageTrackingPermission.askUserForPermission();
      }
    });

    this.subscriptions.add(privacyPopupUrlSubscription);
  }

  private sendApplicationStartUsageTracking() {
    const applicationStartUsageTracking = this.generateNavigateUsageTracking(
      document.referrer,
      this.router.url,
    );

    const afterNavigation = this.router.getCurrentNavigation();
    this.urlTracking.collectUrlTrackingParameter(afterNavigation?.finalUrl?.queryParamMap);

    this.usageTrackingService.send(
      UsageTrackingMessageType.Navigation,
      applicationStartUsageTracking,
    );
  }

  @HostListener('window:beforeunload')
  endApplicationStartUsageTracking(event: Event) {
    const nextUrl = (event as any)?.href || (document.activeElement as any).href || UNKNOWN;

    const applicationStartUsageTracking = this.generateNavigateUsageTracking(
      this.router.url,
      nextUrl,
    );

    this.usageTrackingService.send(
      UsageTrackingMessageType.Navigation,
      applicationStartUsageTracking,
    );
  }

  private initUsageTrackingService() {
    const routerSubscription = this.routerEvents
      .pipe(pairwise())
      .subscribe(([previousRoutingEvent, currentRoutingEvent]) => {
        const navigationUsageTracking = this.generateNavigateUsageTracking(
          previousRoutingEvent.urlAfterRedirects,
          currentRoutingEvent.urlAfterRedirects,
        );

        this.urlTracking.collectUrlTrackingParameter(
          this.router.parseUrl(currentRoutingEvent.urlAfterRedirects)?.queryParamMap,
        );

        this.usageTrackingService.send(
          UsageTrackingMessageType.Navigation,
          navigationUsageTracking,
        );
      });

    this.subscriptions.add(routerSubscription);
  }

  private generateNavigateUsageTracking(
    previousUrl: string,
    currentUrl: string,
  ): NavigationUsageTracking {
    return {
      currentUrl,
      previousUrl,
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public login() {
    this.authService.login();
  }

  public logout() {
    this.authService.logout();
  }
}
