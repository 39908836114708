<div class="title">
  <div class="line" [style.background-color]="lineColor"></div>
  <h2 class="name">{{ title }}</h2>
</div>

<div class="details basic-atom" [class.selected]="checked">
  <lsb-radio-button class="radio-button" [value]="method" [checked]="checked">
    <!-- hint: radio-group must be set on parent component -->
  </lsb-radio-button>

  <div class="caption-labels">
    <ng-container *ngIf="costsPerPart$ | async as costsPerPart">
      <xui-caption-label
        [caption]="
          (method === 'arc'
            ? translations.CALCULATOR.CALCULATION_DETAILS.ARC_COSTS_PER_PART
            : translations.CALCULATOR.CALCULATION_DETAILS.LASER_COSTS_PER_PART
          ) | translate
        "
        [label]="costsPerPartValue.value"
      >
        <lsb-value #costsPerPartValue [binding]="costsPerPart">
          <lsb-number-formatter
            [maxDecimals]="2"
            [unit]="'€/p' | currencyUnit"
          ></lsb-number-formatter>
        </lsb-value>

        <xui-icon
          class="info-flag per-part"
          iconKey="Tooltip.Information"
          [size]="IconSize.S"
          [xuiTooltip]="costPerPartTooltip"
        ></xui-icon>

        <xui-tooltip #costPerPartTooltip>
          <ng-container *ngIf="costsPerPartDetails$ | async as costsPerPartDetails">
            <lsb-number-list>
              <lsb-number-list-item
                *ngFor="let detail of costsPerPartDetails"
                [value]="detail.value"
                [unit]="detail.unit | currencyUnit"
                [translationKey]="detail.translationKey"
              ></lsb-number-list-item>
            </lsb-number-list>
          </ng-container>
        </xui-tooltip>
      </xui-caption-label>
    </ng-container>

    <ng-container *ngIf="costsTotal$ | async as costsTotal">
      <xui-caption-label
        [caption]="
          (method === 'arc'
            ? translations.CALCULATOR.CALCULATION_DETAILS.ARC_COSTS_TOTAL
            : translations.CALCULATOR.CALCULATION_DETAILS.LASER_COSTS_TOTAL
          ) | translate
        "
        [label]="costsPerPartValue.value"
      >
        <lsb-value #costsPerPartValue [binding]="costsTotal">
          <lsb-number-formatter
            [maxDecimals]="2"
            [unit]="'€' | currencyUnit"
          ></lsb-number-formatter>
        </lsb-value>

        <xui-icon
          class="info-flag total"
          iconKey="Tooltip.Information"
          [size]="IconSize.S"
          [xuiTooltip]="costTotalTooltip"
        ></xui-icon>

        <xui-tooltip #costTotalTooltip>
          <ng-container *ngIf="costsTotalDetails$ | async as costsTotalDetails">
            <lsb-number-list>
              <lsb-number-list-item
                *ngFor="let detail of costsTotalDetails"
                [value]="detail.value"
                [unit]="detail.unit | currencyUnit"
                [translationKey]="detail.translationKey"
              ></lsb-number-list-item>
            </lsb-number-list>
          </ng-container>
        </xui-tooltip>
      </xui-caption-label>
    </ng-container>
  </div>

  <div class="stacked options">
    <xui-checkbox
      class="option"
      [checked]="parameters?.parallelLoading || false"
      (checkedChange)="mainTimeParallelLoadingChange.emit($event)"
    >
      <div class="horizontal-layout">
        <span>
          {{ translations.CALCULATOR.CALCULATION_DETAILS.MAIN_TIME_PARALLEL_LOADING | translate }}
        </span>
        <xui-icon
          class="info-flag total"
          data-ngtx="mpt-loading-tooltip"
          iconKey="Tooltip.Information"
          [size]="IconSize.S"
          [xuiTooltip]="mptLoadingTooltip"
          xuiTooltipPosition="top left"
        >
          <xui-tooltip #mptLoadingTooltip>
            {{
              translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.MAIN_TIME_PARALLEL_LOADING
                | translate
            }}
          </xui-tooltip>
        </xui-icon>
      </div>
    </xui-checkbox>

    <div class="space-top">
      <xui-checkbox
        *ngIf="method === 'arc'"
        class="option"
        [disabled]="true"
        [checked]="parameters?.robotWelding || false"
      >
        {{ translations.CALCULATOR.CALCULATION_DETAILS.ROBOT_WELDING | translate }}
      </xui-checkbox>
    </div>
  </div>
</div>
