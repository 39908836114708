import { Injectable } from '@angular/core';
import { WeldingTechnology } from '@shared/types';
import { LocalStorageService } from '@trumpf/xguide';
import { LocalStorageKeys } from '../../../constants';
import { LocalStoragePersistenceStrategy } from '../../../shared/data-persistence/strategies/local-storage.persistence';
import { DataMigrationRule } from '../../../shared/data-persistence/types';
import { VersionedDataPersistenceService } from '../../../shared/data-persistence/versioned-data-persistence.service';
import { MachineConfiguration, WeldingMachineModel } from '../../types';
import { createConfiguration } from '../../utility';

/**
 * Service that reads/saves configurator data from/to Browser local storage
 */
@Injectable({
  providedIn: 'root',
})
export class ConfiguratorDataAccessService extends VersionedDataPersistenceService<MachineConfiguration> {
  protected migrationRules: DataMigrationRule[] = [
    {
      migrateToVersion: 1,
      migrate: (data: MachineConfiguration) => {
        if (!data.machineModelName) {
          data.machineModelName = 'TruLaser Weld 5000';
        }
        if (!data.weldingTechnology) {
          data.weldingTechnology = 'laser';
        }

        if (data.opticNozzleOptions.opticalType === 'BE0_D70') {
          data.opticNozzleOptions.opticalType = 'BEO_D70';
        }
      },
    },
    {
      migrateToVersion: 2,
      migrate: (data: MachineConfiguration) => {
        if (!data.cabinOptions.robotType) {
          data.cabinOptions.robotType = 'REGULAR';
        }
      },
    },
    {
      migrateToVersion: 3,
      migrate: (data: MachineConfiguration) => {
        if (
          data.machineModelName === 'TruLaser Weld 1000' ||
          data.machineModelName === 'TruArc Weld 1000'
        ) {
          data.cabinOptions.robotType = 'REGULAR';
        }

        return data;
      },
    },
  ];

  constructor(localStorageService: LocalStorageService) {
    super(
      new LocalStoragePersistenceStrategy<MachineConfiguration>(
        localStorageService,
        LocalStorageKeys.configurator.database,
      ),
    );
  }

  public createEmpty(
    machineModelName: WeldingMachineModel,
    weldingTechnology: WeldingTechnology,
  ): MachineConfiguration {
    const id = this.getNextId();
    return createConfiguration(machineModelName, weldingTechnology, id);
  }

  public getNextId() {
    return getNextConfigurationId(this._data);
  }
}

function getNextConfigurationId(configurations: MachineConfiguration[]): string {
  if (configurations === undefined || configurations.length === 0) {
    return '1';
  }

  const maxId = Math.max(...configurations.map((c) => parseInt(c.id, 10)));

  if (isNaN(maxId)) {
    return (configurations.length + 1).toString();
  } else {
    return (maxId + 1).toString();
  }
}
