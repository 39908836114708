<ng-container *ngIf="hasCalculations$ | async as hasCalculations; else empty">
  <ng-container *ngIf="calculation$ | async as calculation">
    <ng-container *ngIf="costPerPart$ | async as costsPerPart">
      <ng-container *ngIf="totalCosts$ | async as totalCosts">
        <div class="split-view">
          <xui-dashboard-tile
            class="tile"
            [title]="translations.DASHBOARD.CALCULATED_PARTS.TITLE_LAST_CALCULATION | translate"
            [dark]="true"
            [titleShadow]="true"
            data-ngtx="split-view-config-tile"
          >
            <lsb-calculated-part-item
              [calculationName]="calculationName$ | async"
              [costsPerPart]="costsPerPartValue.value"
              [totalCosts]="totalCostsValue.value"
              (select)="gotoCalculation(calculation, false)"
              (edit)="gotoCalculation(calculation, true)"
            >
              <lsb-value #costsPerPartValue [binding]="costsPerPart.label">
                <lsb-number-formatter [maxDecimals]="2" unit="€/p"></lsb-number-formatter>
                <lsb-apply-value property="label" [target]="costsPerPart"></lsb-apply-value>
              </lsb-value>

              <lsb-value #totalCostsValue [binding]="totalCosts.label">
                <lsb-number-formatter [maxDecimals]="2" unit="€"></lsb-number-formatter>
                <lsb-apply-value property="label" [target]="totalCosts"></lsb-apply-value>
              </lsb-value>
            </lsb-calculated-part-item>
          </xui-dashboard-tile>

          <xui-dashboard-tile
            class="tile"
            [isAction]="true"
            [actionLabel]="translations.DASHBOARD.CALCULATED_PARTS.ADD_ACTION_LABEL | translate"
            (actionClick)="onCreatePartCalculation()"
            data-ngtx="split-view-action-tile"
          ></xui-dashboard-tile>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #empty>
  <xui-dashboard-tile
    class="tile full-height"
    [isAction]="true"
    [dark]="true"
    [actionLabel]="translations.DASHBOARD.CALCULATED_PARTS.ADD_ACTION_LABEL | translate"
    [title]="translations.DASHBOARD.CALCULATED_PARTS.TITLE_NO_CALCULATION | translate"
    (actionClick)="onCreatePartCalculation()"
    data-ngtx="action-tile"
  ></xui-dashboard-tile>
</ng-template>
