import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IForceUpdateView, ITransform, IValidator } from '../../types';

/**
 * Allows you to bind against a view model property in order to manipulate it via value-transforms.
 * Value-transforms are other components such as `TimeConverterComponent` or `NumberFormatterComponent`
 * that can be applied by adding them as children to this component.
 *
 * Each value transform can transform and "transform back" the value given. Moreover this component
 * lets you add validation to your bound value. Whenever the user changes your transformed value,
 * this particular value then gets validated before being transformed back. For the case that validation fails,
 * you can provide a fallback value that is used then.
 *
 * ---
 *
 * ~~~html
 * <lsb-value #ageValue [(binding)]="age" [fallbackValue]="18" [lsbValidateNumber]="{ greaterThanOrEqual: 18 }">
 *   <!-- insert value-transforms here: -->
 *   <lsb-number-formatter [maxDecimals]="0"></lsb-number-formatter>
 * </lsb-value>
 *
 * <lsb-input-field [(text)]="ageValue.value"></lsb-input-field>
 * ~~~
 *
 * ---
 * **Please note:** The order of the value-transforms is important, as it is the exact
 * same order, in which the transformations will get applied.
 * ---
 * **Please note:** This component will not actually render anything, but is
 * completely invisible to the user.
 * ---
 * **Please note:** For more details see the `README.md`.
 */
@Component({
  selector: 'lsb-value',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueComponent<T = any> implements ITransform<T>, IForceUpdateView<T> {
  @Input() debug?: boolean;
  @Input() binding!: T;
  @Output() bindingChange = new EventEmitter<T>();
  @Input() value?: T;
  @Output() valueChange = new EventEmitter<T | undefined>();
  @Input() fallbackValue?: T;
  @Input() unit?: string;
  @Output() unitChange = new EventEmitter<string>();

  private transforms: ITransform<T>[] = [];
  private transformsReversed: ITransform<T>[] = [];
  private validators: IValidator<T>[] = [];

  private cachedValue?: T;
  private cachedUnit?: string;
  private fallbackValueView?: T;

  ngDoCheck(): void {
    const hasValueChanged = this.cachedValue !== this.value;
    const hasUnitChanged = this.cachedUnit !== this.unit;

    if (hasValueChanged) {
      this.cachedValue = this.value;
      requestAnimationFrame(() => this.applyViewToData());
    }

    if (hasUnitChanged) {
      this.cachedUnit = this.unit;
      requestAnimationFrame(() => this.unitChange.emit(this.unit));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.binding) {
      requestAnimationFrame(() => this.applyDataToView());
    }

    if (changes.fallbackValue) {
      this.updateFallbackValue(changes.fallbackValue.firstChange);
    }
  }

  ngAfterViewInit(): void {
    if (this.unit == null) {
      const unit = this.transforms.find((t) => !!t.unit)?.unit;

      if (unit) {
        this.setUnit(unit);
      }
    }
  }

  public register(transform: ITransform<T>): void {
    this.transforms.push(transform);
    this.transformsReversed = [...this.transforms].reverse();
  }

  public unregister(transform: ITransform<T>): void {
    this.transforms = this.transforms.filter((t) => t !== transform);
    this.transformsReversed = [...this.transforms].reverse();
  }

  public registerValidator(validator: IValidator<T>) {
    this.validators.push(validator);
  }

  public unregisterValidator(validator: IValidator<T>) {
    this.validators = this.validators.filter((v) => v !== validator);
  }

  public forceUpdateView(value: T): void {
    requestAnimationFrame(() => {
      this.value = value;
      this.valueChange.emit(this.value);
    });
  }

  public transform(value: T | undefined): T | undefined {
    let result = value;

    try {
      for (const transformer of this.transforms) {
        result = transformer.transform(result);

        if (this.debug != null) console.log('trans', transformer.constructor.name, result);
      }
    } catch (err) {
      result = this.fallbackValue;
    }

    return result;
  }

  public transformBack(value: T | undefined): T | undefined {
    let result = value;

    try {
      for (const transformer of this.transformsReversed) {
        result = transformer.transformBack(result);

        if (this.debug != null) console.log('back', transformer.constructor.name, result);
      }
    } catch (err) {
      result = this.fallbackValueView;
    }

    return result;
  }

  public setUnit(unit: string) {
    requestAnimationFrame(() => {
      this.unit = unit;
      this.unitChange.emit(unit);
    });
  }

  private isValid(value: T | undefined): boolean {
    return this.validators
      .filter((validator) => validator.canValidate(value))
      .every((validator) => validator.isValid(value));
  }

  private applyDataToView() {
    const value = this.transform(this.binding);

    if (this.value !== value) {
      this.value = value;
      this.valueChange.emit(this.value);
    }
  }

  private applyViewToData() {
    const isValueValid = this.isValid(this.value);
    const binding = isValueValid ? this.transformBack(this.value) : this.fallbackValue;

    if (!isValueValid && this.fallbackValueView) {
      this.forceUpdateView(this.fallbackValueView);
    }

    if (this.binding !== binding) {
      this.binding = binding as T;
      this.bindingChange.emit(this.binding);
    }
  }

  private updateFallbackValue(firstChange: boolean) {
    if (firstChange) {
      // wait for value transforms to be registered
      requestAnimationFrame(() => this.doUpdateFallbackValueNow());
    } else {
      this.doUpdateFallbackValueNow();
    }
  }

  private doUpdateFallbackValueNow() {
    this.fallbackValueView = this.transform(this.fallbackValue);
    this.checkFallbackValidity();
  }

  private checkFallbackValidity() {
    if (!this.isValid(this.fallbackValue)) {
      console.warn(`fallbackValue does not pass validators: ${this.fallbackValue}`);
    }
    if (!this.isValid(this.fallbackValueView)) {
      console.warn(`fallbackValueView does not pass validators: ${this.fallbackValueView}`);
    }
  }
}
