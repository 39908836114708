import { Directive, Host, OnDestroy, OnInit } from '@angular/core';
import { ValueComponent } from '../components/value/value.component';
import { IValidator } from '../types';

/**
 * Template directive for creating custom, value-validator directives that work with the `lsb-value` component.
 * In order to use this directive just extend from it and implement the abstract methods.
 *
 * ---
 *
 * ~~~html
 * <lsb-value
 *   [(binding)]="age"
 *   [lsbValidateNumber]="{ greaterThanOrEqual: 0, lessThan: 120 }"
 *   [fallback]="0"
 *   #validated
 * ></lsb-value>
 *
 * <lsb-input-field [(text)]="validated.value"></lsb-input-field>
 * <p>Your age is: {{ age }}€ </p>
 * ~~~
 */
@Directive({})
export abstract class ValidatorBase<T> implements IValidator<T>, OnInit, OnDestroy {
  constructor(@Host() private value: ValueComponent) {}

  ngOnInit() {
    this.value.registerValidator(this);
  }

  ngOnDestroy() {
    this.value.unregisterValidator(this);
  }

  abstract canValidate(value: unknown): boolean;
  abstract isValid(value: unknown): boolean;
}
