import { Injectable } from '@angular/core';
import {
  CalculatorSettings,
  PartCalculation,
  PartCalculationResult,
  PartCalculationResultDetailsPerPart,
  PartCalculationResultDetailsTotal,
  WeldingMethodParameters,
  WeldingParameters,
  WeldingTechnology,
} from '@shared/types';
import { SettingsAccessor, SettingsService } from '@trumpf/xguide';
import { CalculatorSettingsDefaultValues, CALCULATOR_SETTINGS_NAMESPACE } from '../../constants';

/**
 * Service responsible for doing part-calculations.
 *
 * ---
 *
 * Preconditions that are enforced by CalculationWizard:
 *
 * - all numbers in `PartCalculation` type must not be negative numbers
 * - properties `PartCalculation.weldingParameters.weldingSpeed` must not be negative or zero.
 */
@Injectable({ providedIn: 'root' })
export class CalculationService {
  private settings: SettingsAccessor<CalculatorSettings>;

  constructor(private settingsService: SettingsService) {
    this.settings = this.settingsService.access(
      CALCULATOR_SETTINGS_NAMESPACE,
      CalculatorSettingsDefaultValues,
    );
  }

  public calculate(input: PartCalculation): PartCalculationResult {
    const calcSettings = this.settings.get();

    return {
      ...this.doCalculation('arc', input, calcSettings),
      ...this.doCalculation('laser', input, calcSettings),
    } as PartCalculationResult;
  }

  private doCalculation(
    method: WeldingTechnology,
    calculation: PartCalculation,
    settings: CalculatorSettings,
  ): Partial<PartCalculationResult> {
    // validity checks
    const knownWeldingMethods: WeldingTechnology[] = ['arc', 'laser'];

    if (!knownWeldingMethods.includes(method)) {
      throw new Error(`Unexpected welding method "${method}".`);
    }

    // base values
    const baseValues = this.getBaseValues(method, calculation, settings);

    // calculation helpers
    const {
      setupCostsInEur,
      tackingCostsInEur,
      weldingCostsInEur,
      measureCostsInEur,
      loadingCostsInEur,
      reworkCostsInEur,
      fixtureCostsInEur,
      programmingCostsInEur,
      weldingTimeInSec,
      loadingCostsWithMtp,
      loadingCostsWithoutMtp,
    } = this.calculateIntermediateResults(baseValues);

    // result
    const costsPerPartInEur =
      setupCostsInEur +
      tackingCostsInEur +
      weldingCostsInEur +
      measureCostsInEur +
      loadingCostsInEur +
      reworkCostsInEur;

    const totalCostsInEur =
      fixtureCostsInEur + programmingCostsInEur + costsPerPartInEur * baseValues.totalPieces;

    const detailsPerPart: PartCalculationResultDetailsPerPart = {
      loadingCosts: loadingCostsInEur,
      measureCosts: measureCostsInEur,
      reworkCosts: reworkCostsInEur,
      setupCosts: setupCostsInEur,
      tackingCosts: tackingCostsInEur,
      weldingCosts: weldingCostsInEur,
      weldingTime: weldingTimeInSec,
      loadingCostsWithMainTimeParallelLoading: loadingCostsWithMtp,
      loadingCostsWithoutMainTimeParallelLoading: loadingCostsWithoutMtp,
    };

    const detailsTotal: PartCalculationResultDetailsTotal = {
      fixtureCosts: fixtureCostsInEur,
      programmingCosts: programmingCostsInEur,
      totalPieces: baseValues.totalPieces,
      totalPiecesCosts: baseValues.totalPieces * costsPerPartInEur,
    };

    // return
    return method === 'laser'
      ? {
          costsPerPartLaser: costsPerPartInEur,
          totalCostsLaser: totalCostsInEur,
          detailsPerPartLaser: detailsPerPart,
          detailsTotalLaser: detailsTotal,
        }
      : {
          costsPerPartArc: costsPerPartInEur,
          totalCostsArc: totalCostsInEur,
          detailsPerPartArc: detailsPerPart,
          detailsTotalArc: detailsTotal,
        };
  }

  private calculateIntermediateResults(
    baseValues: CalculationBaseValues,
  ): IntermediateCalculationResults {
    const {
      totalSeamLengthInMillimeters,
      weldingSpeedInMillimetersPerSec,
      amountSeams,
      entryExitTimePerSeamInSec,
      totalPieces,
      batchSize,
      setupTimePerBatchInSec,
      tackingTimeInSec,
      measureTimeInSec,
      reworkTimeInSec,
      hourlyRateArcManualInEuroPerSec,
      programmingTimeInSec,
      chosenHourlyRate,
      chosenConfig,
    } = baseValues;

    const weldingTimeInSec =
      totalSeamLengthInMillimeters / weldingSpeedInMillimetersPerSec +
      amountSeams * entryExitTimePerSeamInSec;

    const setupCostsInEur =
      ((totalPieces / batchSize) * setupTimePerBatchInSec * chosenHourlyRate) / totalPieces;

    const tackingCostsInEur = tackingTimeInSec * hourlyRateArcManualInEuroPerSec;
    const weldingCostsInEur = weldingTimeInSec * chosenHourlyRate;
    const measureCostsInEur = measureTimeInSec * chosenHourlyRate;
    const reworkCostsInEur = reworkTimeInSec * hourlyRateArcManualInEuroPerSec;

    const loadingCostsInEur = this.calculateLoadingCosts(baseValues, weldingTimeInSec);
    const loadingCostsWithMtp = this.setMainTimeParallelLoadingAndCalculateLoadingCosts(
      baseValues,
      weldingTimeInSec,
      true,
    );
    const loadingCostsWithoutMtp = this.setMainTimeParallelLoadingAndCalculateLoadingCosts(
      baseValues,
      weldingTimeInSec,
      false,
    );

    const programmingCostsInEur = programmingTimeInSec * chosenHourlyRate;
    const fixtureCostsInEur = chosenConfig.fixtureCostsInEur;

    return {
      setupCostsInEur,
      tackingCostsInEur,
      weldingCostsInEur,
      measureCostsInEur,
      loadingCostsInEur,
      reworkCostsInEur,
      fixtureCostsInEur,
      programmingCostsInEur,
      weldingTimeInSec,
      loadingCostsWithMtp,
      loadingCostsWithoutMtp,
    };
  }

  private calculateLoadingCosts(
    baseValues: CalculationBaseValues,
    weldingTimeInSec: number,
  ): number {
    const { measureTimeInSec, loadingTimeInSec, chosenHourlyRate, chosenParams } = baseValues;

    let loadingCostsInEur: number = 0;
    const turningTimeInSec = 3;

    if (chosenParams.parallelLoading) {
      const loadingTime =
        weldingTimeInSec + measureTimeInSec > loadingTimeInSec
          ? turningTimeInSec
          : turningTimeInSec + loadingTimeInSec - weldingTimeInSec - measureTimeInSec;

      loadingCostsInEur = loadingTime * chosenHourlyRate;
    } else {
      loadingCostsInEur = loadingTimeInSec * chosenHourlyRate;
    }

    return loadingCostsInEur;
  }

  private setMainTimeParallelLoadingAndCalculateLoadingCosts(
    baseValues: CalculationBaseValues,
    weldingTimeInSec: number,
    useMtp: boolean,
  ): number {
    const params: WeldingMethodParameters = { ...baseValues.chosenParams, parallelLoading: useMtp };
    const baseValuesWithMtpSetting: CalculationBaseValues = { ...baseValues, chosenParams: params };

    return this.calculateLoadingCosts(baseValuesWithMtpSetting, weldingTimeInSec);
  }

  private getBaseValues(
    method: string,
    calculation: PartCalculation,
    settings: CalculatorSettings,
  ): CalculationBaseValues {
    const config = calculation.configuration;

    const chosenConfig =
      method === 'laser' ? config.laserWeldingParameters : config.arcWeldingParameters;
    const chosenParams =
      method === 'laser' ? calculation.parameters.laser : calculation.parameters.arc;

    let chosenHourlyRate: number;

    if (method === 'laser') {
      chosenHourlyRate = settings.hourlyRateLaserInEurPerHour;
    } else if (chosenParams.robotWelding) {
      chosenHourlyRate = settings.hourlyRateArcRoboterInEurPerHour;
    } else {
      chosenHourlyRate = settings.hourlyRateArcManualInEurPerHour;
    }

    return {
      // calculation basics
      totalPieces: config.basics.totalPieces,
      totalSeamLengthInMillimeters: config.basics.totalSeamLength,
      batchSize: config.basics.batchSize,
      amountSeams: config.basics.amountSeams,
      // welding method specifics
      setupTimePerBatchInSec: chosenConfig.setupTimePerBatchInSec,
      tackingTimeInSec: chosenConfig.tackingTimeInSec,
      measureTimeInSec: chosenConfig.measureTimeInSec,
      weldingSpeedInMillimetersPerSec: chosenConfig.weldingSpeedInMillimetersPerSec,
      reworkTimeInSec: chosenConfig.reworkTimeInSec,
      loadingTimeInSec: chosenConfig.loadingTimeInSec,
      programmingTimeInSec: chosenConfig.programmingTimeInSec,
      // settings
      hourlyRateArcManualInEuroPerSec: settings.hourlyRateArcManualInEurPerHour,
      entryExitTimePerSeamInSec: settings.entryExitTimePerSeamInSec,
      // value sources
      chosenHourlyRate,
      chosenParams,
      chosenConfig,
    };
  }
}

// --------------------------------------
// --------- Module Internals -----------
// --------------------------------------

/** All values needed to calculate the costs for each welding method */
interface CalculationBaseValues {
  totalSeamLengthInMillimeters: number;
  weldingSpeedInMillimetersPerSec: number;
  amountSeams: number;
  entryExitTimePerSeamInSec: number;
  totalPieces: number;
  batchSize: number;
  setupTimePerBatchInSec: number;
  chosenHourlyRate: number;
  tackingTimeInSec: number;
  measureTimeInSec: number;
  reworkTimeInSec: number;
  hourlyRateArcManualInEuroPerSec: number;
  chosenParams: WeldingMethodParameters;
  loadingTimeInSec: number;
  programmingTimeInSec: number;
  chosenConfig: WeldingParameters;
}

/** All calculation helper values (improving the readability of the result-formula) */
interface IntermediateCalculationResults {
  setupCostsInEur: number;
  tackingCostsInEur: number;
  weldingCostsInEur: number;
  measureCostsInEur: number;
  loadingCostsInEur: number;
  reworkCostsInEur: number;
  fixtureCostsInEur: number;
  programmingCostsInEur: number;
  weldingTimeInSec: number;
  loadingCostsWithoutMtp: number;
  loadingCostsWithMtp: number;
}
