import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DropdownComponent } from '@trumpf/xguide';
import { ConfiguratorLayoutModel } from '../../../../../../shared';
import { CabinInfo } from '../../../../../../shared/types/cabin-info';
import { onlyUnique, TranslationHelper } from '../../../shared/helpers';
import {
  ConfigurationWizardView,
  MachineCabinOptions,
  MachineConfiguration,
  RobotInfo,
  RobotType,
} from '../../types';
import { getLayoutResourceUrl } from '../../utility';

@Component({
  selector: 'lsb-robot-safety-cabin',
  templateUrl: './robot-safety-cabin.component.html',
  styleUrls: ['./robot-safety-cabin.component.scss'],
})
export class RobotSafetyCabinComponent implements OnChanges, ConfigurationWizardView {
  @Input() layoutModels: ConfiguratorLayoutModel[] = [];
  @Input() configuration: MachineConfiguration;
  @Output() configurationChange = new EventEmitter<MachineConfiguration>();
  @Output() cabinLayoutChange = new EventEmitter<CabinInfo>();

  get cabinIllustrationUrl(): string {
    return getLayoutResourceUrl(
      this.configuration?.machineModelName,
      this.configuration?.cabinOptions,
      this.configuration?.componentOptions,
      'image',
    );
  }

  public cabins: CabinInfo[] = [];
  public selectedCabin: MachineCabinOptions;
  public robots: RobotInfo[] = [];
  public selectedRobot?: string;

  constructor(public translations: TranslationHelper) {}

  ngOnChanges() {
    const cabinOptions = this.configuration?.cabinOptions;

    if (!cabinOptions?.cabinSize || !cabinOptions?.cabinLayout) {
      const firstCabin = this.cabins[0];
      cabinOptions.cabinSize = firstCabin?.size;
      cabinOptions.cabinLayout = firstCabin?.layout;
    }

    this.cabins = this.layoutModels
      .map(toCabinInfo)
      // TODO: langju: remove this filter once we want to re-include 7x5 and 8x4 layouts
      .filter(removeCertainLayouts)
      .filter(onlyUnique('layout'));
    this.selectedCabin = this.configuration?.cabinOptions;

    this.robots = [
      { type: 'ION', name: 'KR30 HA Iontec (Standard)' },
      { type: 'REGULAR', name: 'KR30 HA (Machines until 06/2022)' },
    ];
    this.selectedRobot = this.robots.find(
      (r) => r.type === this.configuration.cabinOptions.robotType,
    )?.name;
  }

  public selectCabin(cabin: CabinInfo) {
    this.configuration.cabinOptions.cabinLayout = cabin.layout;
    this.configuration.cabinOptions.cabinSize = cabin.size;

    this.configurationChange.emit({ ...this.configuration });
    this.cabinLayoutChange.emit({ ...cabin });
  }

  public selectRobot(robotType: RobotType, dropDown: DropdownComponent) {
    this.configuration.cabinOptions.robotType = robotType;
    dropDown.close();

    this.configurationChange.emit({ ...this.configuration });
  }
}

function toCabinInfo(layout: ConfiguratorLayoutModel): CabinInfo {
  return layout.cabin;
}

function removeCertainLayouts(cabin: CabinInfo): boolean {
  return cabin.layout !== '7x5' && cabin.layout !== '8x4';
}
