import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ITransform } from '../../types';
import { ValueComponent } from '../value/value.component';

/**
 * Template component for creating custom transform components that work with the `lsb-value` component.
 * In order to use this component just extend from it and implement the abstract methods.
 */
@Component({
  selector: 'lsb-transform',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class TransformComponent implements OnInit, OnDestroy, ITransform<any> {
  constructor(protected value: ValueComponent) {}

  ngOnInit(): void {
    this.value.register(this);
  }

  ngOnDestroy(): void {
    this.value.unregister(this);
  }

  abstract transform(value: any): any;

  abstract transformBack(value: any): any;
}
