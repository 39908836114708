import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsageTrackingModule } from '@trumpf/xguide';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { ConfiguratorRoutingModule } from './configurator-routing.module';
import { ConfiguratorModellingService, ConfiguratorModellingServiceMock } from './services';
import { ConfigurationWizardStepTwoComponent } from './views/configuration-wizard-step-two/configuration-wizard-step-two.component';
import { ConfiguratorComponentOptionItemComponent } from './views/configuration-wizard-step-two/configurator-component-option-item/configurator-component-option-item.component';
import { ConfigurationWizardComponent } from './views/configuration-wizard/configuration-wizard.component';
import { ConfiguratorListItemContainerComponent } from './views/configurator/configurator-list-item-container/configurator-list-item-container.component';
import { ConfiguratorListItemComponent } from './views/configurator/configurator-list-item/configurator-list-item.component';
import { ConfiguratorComponent } from './views/configurator/configurator.component';
import { CreateConfigurationDialogViewComponent } from './views/create-configuration-dialog-view/create-configuration-dialog-view.component';
import { CreateConfigurationViewComponent } from './views/create-configuration-view/create-configuration-view.component';
import { OpticConfigurationComponent } from './views/optic-configuration/optic-configuration.component';
import { RobotSafetyCabinComponent } from './views/robot-safety-cabin/robot-safety-cabin.component';

@NgModule({
  imports: [
    // <this comment forces items to be placed below each other>
    CommonModule,
    ConfiguratorRoutingModule,
    SharedModule,
    UsageTrackingModule.forChild(),
  ],
  declarations: [
    ConfigurationWizardComponent,
    ConfigurationWizardStepTwoComponent,
    ConfiguratorComponent,
    ConfiguratorComponentOptionItemComponent,
    ConfiguratorListItemComponent,
    ConfiguratorListItemContainerComponent,
    CreateConfigurationDialogViewComponent,
    CreateConfigurationViewComponent,
    OpticConfigurationComponent,
    RobotSafetyCabinComponent,
  ],
  providers: [
    {
      provide: ConfiguratorModellingService,
      useClass: environment.mock ? ConfiguratorModellingServiceMock : ConfiguratorModellingService,
    },
  ],
})
export class ConfiguratorModule {}
