import { typeOf } from '../../../shared/helpers/type-helper';
import {
  ComponentOptions,
  ComponentToFilenamePartConverter,
  MachineCabinOptions,
  MachineConfigComponentItem,
  MachineConfigDesk,
  MachineConfigNozzleType,
  MachineConfigPosition,
  OpticNozzleOptions,
  ResourceType,
  WeldingMachineModel,
} from '../../types';

/**
 * Composes a layout resource's name from the given cabin- and component options.
 * @param cabinOptions The cabin options to be used to compose the resource name.
 * @param componentOptions The component options to be uesed to compose the resource name.
 */
export function composeLayoutResourceName(
  machineModel: WeldingMachineModel,
  cabinOptions: MachineCabinOptions,
  componentOptions: ComponentOptions,
) {
  let fileNamePrefix = 'TLW5000';

  if (machineModel === 'TruArc Weld 1000') {
    fileNamePrefix = 'TAW1000';
  } else if (machineModel === 'TruLaser Weld 1000') {
    fileNamePrefix = 'TLW1000';
  }

  const layout = cabinOptions.cabinLayout;
  const robotType = cabinOptions.robotType === 'ION' ? 'ION' : '';
  const linearAxis = CabinLayoutsWithLinearAxis.includes(layout) ? 'LIN' : '';
  const plate =
    componentOptions.deskRef.value === MachineConfigDesk.ZeroPointClampingSystem ? 'NPSS' : '';

  const components: string[] = componentOptions.componentItems
    .filter((component) => component.isChecked)
    .sort(
      // align in same order as the backend-file are named:
      inOrder(BackendImageFilenameComponentOrder),
    )
    .map(tryConvert);

  const url = composeResourceName(fileNamePrefix, [
    layout,
    robotType,
    linearAxis,
    ...components,
    plate,
  ]);

  return url;
}

/**
 * Reads the given optic nozzle options and returns the resource URL of the associated image or cad-model.
 * @param opticNozzleOptions The optic nozzle options to convert into the associated resource (image or cad model) url.
 * @param type (Optional) The desired resource type which can be "image" or "cad-model". Defaults to "image".
 */
export function getOpticResourceUrl(
  opticNozzleOptions: OpticNozzleOptions,
  type: ResourceType = 'image',
): string {
  const fileExt = getFileExtension(type);
  /* 
    hint: langju: we assume that the folder in S3 Bucket matches exactly the opticalType string.
    this way we don't need to update the code when adding further optical types:
  */
  const imageFolder = opticNozzleOptions.opticalType;

  let nozzleType = '';

  switch (opticNozzleOptions.nozzleType) {
    case MachineConfigNozzleType.LinearNozzle:
      nozzleType = 'LINEAR';
      break;
    case MachineConfigNozzleType.PerlatorNozzle:
      nozzleType = 'PERLATOR';
      break;
    case MachineConfigNozzleType.Coaxial:
      nozzleType = 'KOAXIAL';
      break;
  }

  const components: string[] = [];

  if (opticNozzleOptions.opticOptions.teachLine) {
    components.push('TLL');
  }
  if (opticNozzleOptions.opticOptions.fusionLine) {
    components.push('FL');
  }
  if (opticNozzleOptions.opticOptions.wire) {
    components.push('WIRE');
  }

  const url = createUrl(
    imageFolder,
    composeResourceName(imageFolder, [...components, nozzleType]),
    fileExt,
  );

  return url;
}

/**
 * Reads the given cabin and component options and returns the resource URL of the associated image or cad-model.
 * @param opticNozzleOptions The cabin and component options to convert into the associated resource (image or cad model) url.
 * @param type (Optional) The desired resource type which can be "image" or "cad-model". Defaults to "image".
 */
export function getLayoutResourceUrl(
  machineModel: WeldingMachineModel,
  cabinOptions: MachineCabinOptions,
  componentOptions: ComponentOptions,
  type: ResourceType = 'image',
): string {
  let imageFolder = 'TLW5000';

  if (machineModel === 'TruArc Weld 1000') {
    imageFolder = 'TAW1000';
  } else if (machineModel === 'TruLaser Weld 1000') {
    imageFolder = 'TLW1000';
  }

  const fileExt = getFileExtension(type);

  return createUrl(
    imageFolder,
    composeLayoutResourceName(machineModel, cabinOptions, componentOptions),
    fileExt,
  );
}

// -----------------------------------------------
// ------------- Module Internals ----------------
// -----------------------------------------------

/** All cabin layouts that have a linear axis. */
const CabinLayoutsWithLinearAxis = ['6x4', '6x5', '7x4', '7x5', '8x4'];

/** Component converters used to convert `MachineConfigComponentItem`s into their respective file name parts. */
const ComponentToFilenamePartConverters: ComponentToFilenamePartConverter[] = [
  // New component: "Rotary Axis" -> ROT
  {
    // TODO: langju: move magic strings to shared consts across services and views!
    componentTag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DKP',
    convert: withPositionDetails('DKP'),
  },
  {
    componentTag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DT',
    convert: withPositionDetails('DT'),
  },
  {
    componentTag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.RW',
    convert: withPositionDetails('RW'),
  },
  {
    componentTag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.WP',
    convert: 'WP',
  },
  {
    componentTag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.ROT',
    convert: 'ROT',
  },
];

/** Determines the order of components as they are used in backend-image file names. */
const BackendImageFilenameComponentOrder = [
  'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DKP', // DKP
  'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DT', // DT
  'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.RW', // RW
  'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.WP', // WP
];
function inOrder(order: string[]) {
  return (a: MachineConfigComponentItem, b: MachineConfigComponentItem) => {
    return order.indexOf(a.tag) - order.indexOf(b.tag);
  };
}

/**
 * Finds the corresponding converter to the specified component and converts it into a file name part.
 * @param component The component to convert into a file name part.
 */
function tryConvert(component: MachineConfigComponentItem): string {
  const converter = ComponentToFilenamePartConverters.find((c) => c.componentTag === component.tag);

  if (!converter) {
    throw new Error(`No component-item converter found for "${component?.tag}"`);
  }

  return typeOf(converter.convert, 'function') ? converter.convert(component) : converter.convert;
}

/**
 * Builds an URL to a file name with the specified file parts.
 * @param folder The image folder in which the image is contained.
 * @param fileName The rest of the filename, like component identifiers with or without position details.
 * @param fileExt The file extension, like ".JPG" or ".STEP".
 */
function createUrl(folder: string, fileName: string, fileExt: string) {
  return folder + '/' + fileName + fileExt;
}

/**
 * Composes a resource's file name (without extension) based on given name-parts.
 * @param fileNamePrefix The prefix of the resource's file name.
 * @param filenameParts The file name parts that will compose the full resource's name.
 */
function composeResourceName(fileNamePrefix: string, filenameParts: string[]) {
  return [fileNamePrefix, ...filenameParts].filter((item) => item !== '').join('_');
}

/**
 * Resolves the correct file extension based on the specified, desired media type.
 * @param type The desired type of media.
 */
function getFileExtension(type: string) {
  return type === 'image' ? '.JPG' : '.STEP';
}

/**
 * Takes a position value and converts it into a position detail string to be appended to a filename part.
 * @param position The position to be converted into a position detail string.
 */
function toPositionString(position: MachineConfigPosition): string {
  return position === MachineConfigPosition.LEFT ? 'L' : 'R';
}

/**
 * Factory function that creates a file name part converter that uses the specified file name part string
 * and appends the position details specified in the component instance to it.
 * @param filenamePart The filename part to be used together with position details computed of the passed component instance.
 */
function withPositionDetails(filenamePart: string) {
  return (component: MachineConfigComponentItem) => {
    if (!component.position) {
      throw new Error(`Cannot add position details to component without position.`);
    }

    return filenamePart + toPositionString(component.position);
  };
}
