<div class="stacked-layout half">
  <lsb-group-container [headline]="translations.opticalType | translate" class="optical-type">
    <span class="optical-type-text">{{ configuration?.opticNozzleOptions.opticalType }}</span>
  </lsb-group-container>

  <div class="horizontal-layout">
    <lsb-group-container [headline]="translations.options | translate" class="options half">
      <div class="label-item">
        <xui-checkbox
          [disabled]="true"
          [checked]="configuration.opticNozzleOptions.opticOptions.rotaryModule"
        >
          {{ translations.rotaryModule | translate }}
        </xui-checkbox>
        <xui-icon
          class="link"
          (click)="navigateOptic('rotary', $event)"
          iconKey="Open.Dialog"
        ></xui-icon>
      </div>
      <div class="label-item">
        <xui-checkbox
          [checked]="configuration.opticNozzleOptions.opticOptions.teachLine"
          (checkedChange)="onOptionsChange('teachline', $event)"
        >
          {{ translations.teachLine | translate }}
        </xui-checkbox>
        <xui-icon
          class="link"
          (click)="navigateOptic('teachline', $event)"
          iconKey="Open.Dialog"
        ></xui-icon>
      </div>
      <div class="label-item">
        <xui-checkbox
          [checked]="configuration.opticNozzleOptions.opticOptions.fusionLine"
          (checkedChange)="onOptionsChange('fusionline', $event)"
        >
          {{ translations.fusionLine | translate }}
        </xui-checkbox>
        <xui-icon
          class="link"
          (click)="navigateOptic('fusionline', $event)"
          iconKey="Open.Dialog"
        ></xui-icon>
      </div>
      <div class="label-item">
        <xui-checkbox
          [checked]="configuration.opticNozzleOptions.opticOptions.wire"
          [disabled]="!configuration.opticNozzleOptions.opticOptions.fusionLine"
          (checkedChange)="onOptionsChange('wire', $event)"
        >
          {{ translations.wire | translate }}
        </xui-checkbox>
        <xui-icon
          class="link"
          (click)="navigateOptic('wire', $event)"
          iconKey="Open.Dialog"
        ></xui-icon>
      </div>
    </lsb-group-container>

    <lsb-group-container [headline]="translations.gasNozzle | translate" class="nozzles half">
      <lsb-radio-group (selectionChange)="onNozzleChange($event)">
        <div class="label-item">
          <lsb-radio-button
            value="linear"
            [label]="translations.linearNozzle | translate"
            [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.LinearNozzle"
          ></lsb-radio-button>
          <xui-icon
            class="link"
            (click)="navigateNozzle('linear', $event)"
            iconKey="Open.Dialog"
          ></xui-icon>
        </div>
        <div class="label-item">
          <lsb-radio-button
            value="perlator"
            [label]="translations.perlatorNozzle | translate"
            [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.PerlatorNozzle"
          ></lsb-radio-button>
          <xui-icon
            class="link"
            (click)="navigateNozzle('perlator', $event)"
            iconKey="Open.Dialog"
          ></xui-icon>
        </div>
        <div class="label-item">
          <lsb-radio-button
            value="coaxial"
            [label]="translations.coaxialNozzle | translate"
            [checked]="configuration?.opticNozzleOptions?.nozzleType === NozzleType.Coaxial"
          ></lsb-radio-button>
          <xui-icon
            class="link"
            (click)="navigateNozzle('coaxial', $event)"
            iconKey="Open.Dialog"
          ></xui-icon>
        </div>
      </lsb-radio-group>
    </lsb-group-container>
  </div>
</div>

<div class="image-preview half">
  <img class="image non-draggable" [src]="cabinIllustrationUrl | imageUrl" />
</div>
