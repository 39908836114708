import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrencyRateUnit, CurrencyUnit, UnitConverter } from '../../../types';
import { ConvertComponent } from '../../components/convert/convert.component';

/**
 * A currency-converter that works with the `<lsb-value>` component:
 *
 * ---
 *
 * ~~~html
 * <lsb-value #priceInCents [binding]="priceInEur">
 *   <lsb-currency-converter targetUnit="eur-cents"></lsb-currency-converter>
 * </lsb-value>
 *
 * <p>This product costs {{ priceInCents.value }} cents.</p>
 * ~~~
 */
@Component({
  selector: 'lsb-currency-converter',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyConverterComponent extends ConvertComponent {
  @Input() targetUnit: CurrencyUnit | CurrencyRateUnit;
  protected knownConverters = [...CurrencyConverters, ...RateConverters];
}

// -----------------------------------------
// ---------- Module Internals -------------
// -----------------------------------------

const RateConverters: UnitConverter<CurrencyRateUnit>[] = [
  {
    from: 'eur/sec',
    to: 'eur/sec',
    convert: (eurPerSec) => eurPerSec,
    convertBack: (eurPerSec) => eurPerSec,
  },
  {
    from: 'eur/sec',
    to: 'eur/min',
    convert: (eurPerSec) => eurPerSec * 60,
    convertBack: (eurPerSec) => eurPerSec / 60,
  },
  {
    from: 'eur/sec',
    to: 'eur/hours',
    convert: (eurPerSec) => eurPerSec * 3600,
    convertBack: (eurPerSec) => eurPerSec / 3600,
  },
];
const CurrencyConverters: UnitConverter<CurrencyUnit>[] = [
  {
    from: 'eur',
    to: 'eur',
    convert: (eur) => eur,
    convertBack: (eur) => eur,
  },
  {
    from: 'eur',
    to: 'eur-cents',
    convert: (eur) => eur * 100,
    convertBack: (eur) => eur / 100,
  },
];
