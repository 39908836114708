import {
  DeskRef,
  MachineConfigComponentItem,
  MachineConfigDesk,
  MachineConfigPosition,
} from '../../../types';
import { CabinPlateState } from './cabin-plate-state';
import { OptionActionType } from './option-action-type';
import { OptionRule } from './option-rule';

export function setDefaultValuesForRulesForBigCabins(
  dkp: MachineConfigComponentItem,
  dt: MachineConfigComponentItem,
  rw: MachineConfigComponentItem,
  wp: MachineConfigComponentItem,
  desk: DeskRef,
  cabinPlateState: CabinPlateState,
) {
  dkp.isEnabled = true;
  dkp.isChecked = false;
  dkp.position = MachineConfigPosition.LEFT;

  dt.isEnabled = true;
  dt.isChecked = false;
  dt.position = MachineConfigPosition.RIGHT;

  rw.isEnabled = false;
  rw.isChecked = false;
  rw.position = undefined;

  wp.isEnabled = false;
  wp.isChecked = true;
  wp.position = undefined;

  desk.value = MachineConfigDesk.DemellerPlate;
  cabinPlateState.demellerPlateEnabled = true;
  cabinPlateState.zeroPointClampingSystemEnabled = false;
}

export const RULES_FOR_BIG_CABINS: OptionRule[] = [
  {
    tag: 'dkp',
    actions: [
      {
        action: OptionActionType.MoveLeft,
        affects: [{ tag: 'dt', actionToApply: OptionActionType.MoveRight }],
      },
      {
        action: OptionActionType.MoveRight,
        affects: [{ tag: 'dt', actionToApply: OptionActionType.MoveLeft }],
      },
      {
        action: OptionActionType.Check,
        affects: [
          { radioButton: 'zeroPoint-clamping-system', actionToApply: OptionActionType.Enable },
        ],
      },
      {
        action: OptionActionType.Uncheck,
        affects: [
          {
            radioButton: 'zeroPoint-clamping-system',
            actionToApply: OptionActionType.Disable,
            blockedByActions: [
              { tag: 'dt', optionState: OptionActionType.Check },
              { tag: 'rw', optionState: OptionActionType.Check },
            ],
          },
        ],
      },
    ],
  },
  {
    tag: 'dt',
    actions: [
      {
        action: OptionActionType.MoveLeft,
        affects: [{ tag: 'dkp', actionToApply: OptionActionType.MoveRight }],
      },
      {
        action: OptionActionType.MoveRight,
        affects: [{ tag: 'dkp', actionToApply: OptionActionType.MoveLeft }],
      },
      {
        action: OptionActionType.Check,
        affects: [
          { radioButton: 'zeroPoint-clamping-system', actionToApply: OptionActionType.Enable },
        ],
      },
      {
        action: OptionActionType.Uncheck,
        affects: [
          {
            radioButton: 'zeroPoint-clamping-system',
            actionToApply: OptionActionType.Disable,
            blockedByActions: [
              { tag: 'dkp', optionState: OptionActionType.Check },
              { tag: 'rw', optionState: OptionActionType.Check },
            ],
          },
        ],
      },
    ],
  },
  {
    tag: 'rw',
    actions: [
      {
        action: OptionActionType.Check,
        affects: [
          { radioButton: 'zeroPoint-clamping-system', actionToApply: OptionActionType.Enable },
        ],
      },
      {
        action: OptionActionType.Uncheck,
        affects: [
          {
            radioButton: 'zeroPoint-clamping-system',
            actionToApply: OptionActionType.Disable,
            blockedByActions: [
              { tag: 'dkp', optionState: OptionActionType.Check },
              { tag: 'dt', optionState: OptionActionType.Check },
            ],
          },
        ],
      },
    ],
  },
];
