import { Component } from '@angular/core';
import { UsageTrackingPermissionService } from '@trumpf/xguide';
import { TranslationHelper } from '../../shared/helpers/translation.helpers';

@Component({
  selector: 'lsb-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss'],
})
export class DataPrivacyComponent {
  constructor(
    public translations: TranslationHelper,
    private trackingPermission: UsageTrackingPermissionService,
  ) {}

  public showPrivacySettings(): void {
    this.trackingPermission.showPrivacySettings();
  }
}
