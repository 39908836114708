import { Directive, Input } from '@angular/core';
import { NumberValidationOptions } from '../types';
import { ValidatorBase } from '../validator.base';

/**
 * A number-validator that works with the `<lsb-value>` component:
 *
 * ---
 *
 * ~~~html
 * <lsb-value
 *   [(binding)]="age"
 *   [lsbValidateNumber]="{ greaterThanOrEqual: 0, lessThan: 120 }"
 *   [fallback]="0"
 *   #validated
 * ></lsb-value>
 *
 * <lsb-input-field [(text)]="validated.value"></lsb-input-field>
 * <p>Your age is: {{ age }} </p>
 * ~~~
 */
@Directive({
  selector: '[lsbValidateNumber]',
})
export class ValidateNumberDirective extends ValidatorBase<string | number> {
  @Input('lsbValidateNumber') opts: Partial<NumberValidationOptions> = {};

  isValid(value: string | number | undefined): boolean {
    if (value == null) {
      return false;
    }

    const asNumber = +value;

    if (isNaN(asNumber)) {
      return false;
    }

    if (this.opts.lessThan != null && asNumber >= this.opts.lessThan) {
      return false;
    }

    if (this.opts.lessThanOrEqual != null && asNumber > this.opts.lessThanOrEqual) {
      return false;
    }

    if (this.opts.greaterThan != null && asNumber <= this.opts.greaterThan) {
      return false;
    }

    if (this.opts.greaterThanOrEqual != null && asNumber < this.opts.greaterThanOrEqual) {
      return false;
    }

    return true;
  }

  canValidate(value: unknown) {
    const valueType = typeof value;
    return valueType === 'string' || valueType === 'number';
  }
}
