<h2 class="title">{{ translations.CALCULATOR.CALCULATIONS | translate }}</h2>

<div class="settings">
  <lsb-label [label]="hourlyRateString('laser')">
    <xui-icon
      class="info-flag hourly-rate-tooltip"
      iconKey="Tooltip.Information"
      [size]="IconSize.S"
      [xuiTooltip]="hourlyRateTooltip"
      header-slot
    ></xui-icon>

    <xui-tooltip #hourlyRateTooltip>
      <p class="simple-text-tooltip">
        {{ translations.CALCULATOR.TOOLTIPS.HOURLY_RATE_LASER | translate }}
      </p>
    </xui-tooltip>

    <lsb-combobox
      [unit]="'€/h' | currencyUnit"
      [valueField]="true"
      [(text)]="hourlyRatePerHourLaser.value"
    >
      <lsb-value
        #hourlyRatePerHourLaser
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [fallbackValue]="settings.hourlyRateLaserInEurPerHour"
        [(binding)]="settings.hourlyRateLaserInEurPerHour"
        (bindingChange)="notifyUpdate()"
      >
        <lsb-currency-converter targetUnit="eur/hours"></lsb-currency-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <lsb-dropdown-item
        *ngFor="let option of DefaultOptions.hourlyRateLaserInEurPerHour"
        [value]="hourlyRatePerHourOption.value"
      >
        <lsb-value
          #hourlyRatePerHourOption
          [binding]="option"
          [fallbackValue]="settings.hourlyRateLaserInEurPerHour"
        >
          <lsb-currency-converter targetUnit="eur/hours"></lsb-currency-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>
      </lsb-dropdown-item>
    </lsb-combobox>
  </lsb-label>

  <lsb-label [label]="hourlyRateString('arc', 'manual')">
    <xui-icon
      class="info-flag hourly-rate-arc-manual"
      iconKey="Tooltip.Information"
      [size]="IconSize.S"
      [xuiTooltip]="hourlyRateArcManual"
      header-slot
    ></xui-icon>

    <xui-tooltip #hourlyRateArcManual>
      <p class="simple-text-tooltip">
        {{ translations.CALCULATOR.TOOLTIPS.HOURLY_RATE_ARC_MANUAL | translate }}
      </p>
    </xui-tooltip>

    <lsb-combobox
      [unit]="'€/h' | currencyUnit"
      [valueField]="true"
      [(text)]="hourlyRateArcManualInEurPerHour.value"
    >
      <lsb-value
        #hourlyRateArcManualInEurPerHour
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [(binding)]="settings.hourlyRateArcManualInEurPerHour"
        [fallbackValue]="settings.hourlyRateArcManualInEurPerHour"
        (bindingChange)="notifyUpdate()"
      >
        <lsb-currency-converter targetUnit="eur/hours"></lsb-currency-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <lsb-dropdown-item
        *ngFor="let option of DefaultOptions.hourlyRateArcManualInEurPerHour"
        [value]="hourlyRateArcManualInEurPerHourOption.value"
      >
        <lsb-value
          #hourlyRateArcManualInEurPerHourOption
          [binding]="option"
          [fallbackValue]="settings.hourlyRateLaserInEurPerHour"
        >
          <lsb-currency-converter targetUnit="eur/hours"></lsb-currency-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>
      </lsb-dropdown-item>
    </lsb-combobox>
  </lsb-label>

  <lsb-label [label]="hourlyRateString('arc', 'robot')">
    <xui-icon
      class="info-flag hourly-rate-arc-robot"
      iconKey="Tooltip.Information"
      [size]="IconSize.S"
      [xuiTooltip]="hourlyRateArcRobot"
      header-slot
    ></xui-icon>

    <xui-tooltip #hourlyRateArcRobot>
      <p class="simple-text-tooltip">
        {{ translations.CALCULATOR.TOOLTIPS.HOURLY_RATE_ARC_ROBOT | translate }}
      </p>
    </xui-tooltip>

    <lsb-combobox
      [unit]="'€/h' | currencyUnit"
      [valueField]="true"
      [(text)]="hourlyRateArcRoboterInEurPerHour.value"
    >
      <lsb-value
        #hourlyRateArcRoboterInEurPerHour
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [(binding)]="settings.hourlyRateArcRoboterInEurPerHour"
        [fallbackValue]="settings.hourlyRateArcRoboterInEurPerHour"
        (bindingChange)="notifyUpdate()"
      >
        <lsb-currency-converter targetUnit="eur/hours"></lsb-currency-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <lsb-dropdown-item
        *ngFor="let option of DefaultOptions.hourlyRateArcRoboterInEurPerHour"
        [value]="hourlyRateArcManualInEurPerHourOption.value"
      >
        <lsb-value
          #hourlyRateArcManualInEurPerHourOption
          [binding]="option"
          [fallbackValue]="settings.hourlyRateLaserInEurPerHour"
        >
          <lsb-currency-converter targetUnit="eur/hours"></lsb-currency-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>
      </lsb-dropdown-item>
    </lsb-combobox>
  </lsb-label>

  <lsb-label [label]="translations.CALCULATOR.ENTRY_EXIT_TIME_PER_SEAM | translate">
    <xui-icon
      class="info-flag entry-exit-time-tooltip"
      iconKey="Tooltip.Information"
      [size]="IconSize.S"
      [xuiTooltip]="entryExitTimeTooltip"
      header-slot
    ></xui-icon>

    <xui-tooltip #entryExitTimeTooltip>
      <p class="simple-text-tooltip">
        {{ translations.CALCULATOR.TOOLTIPS.ENTRY_EXIT_TIME_PER_SEAM | translate }}
      </p>
    </xui-tooltip>

    <lsb-combobox [valueField]="true" unit="sec" [(text)]="entryExitTimePerSeamInSec.value">
      <lsb-value
        #entryExitTimePerSeamInSec
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThan: 0 }"
        [(binding)]="settings.entryExitTimePerSeamInSec"
        [fallbackValue]="settings.entryExitTimePerSeamInSec"
        (bindingChange)="notifyUpdate()"
      >
        <lsb-time-converter targetUnit="sec"></lsb-time-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <lsb-dropdown-item
        *ngFor="let option of DefaultOptions.entryExitTimePerSeamInSec"
        [value]="entryExitTimePerSeamInSecOption.value"
      >
        <lsb-value
          #entryExitTimePerSeamInSecOption
          [binding]="option"
          [fallbackValue]="settings.hourlyRateLaserInEurPerHour"
        >
          <lsb-time-converter targetUnit="sec"></lsb-time-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>
      </lsb-dropdown-item>
    </lsb-combobox>
  </lsb-label>
</div>
