import { WeldingTechnology } from '@shared/types';
import { Storable } from '../../shared/data-persistence/types';
import {
  MachineConfigDesk,
  MachineConfigNozzleType,
  MachineConfigPosition,
  MachineConfiguration,
  WeldingMachineModel,
} from '../types';

/**
 * Copies the given machine configuration object deeply and returns the copy.
 * @param machine The machine configuration to return a copy of.
 */
export function copyOfMachine(machine: MachineConfiguration): MachineConfiguration;
export function copyOfMachine(
  machine: Omit<MachineConfiguration, 'id'>,
): Omit<MachineConfiguration, 'id'>;
export function copyOfMachine(
  machine: Storable<MachineConfiguration>,
): Storable<MachineConfiguration> {
  const copy = { ...machine };
  copy.cabinOptions = { ...machine.cabinOptions };
  copy.componentOptions = {
    ...machine.componentOptions,
    componentItems: machine.componentOptions.componentItems.map((c) => ({ ...c })),
  };
  copy.opticNozzleOptions = {
    ...machine.opticNozzleOptions,
    opticOptions: { ...machine.opticNozzleOptions.opticOptions },
  };

  return copy;
}

export function createConfiguration(
  machineModelName: WeldingMachineModel,
  weldingTechnology: WeldingTechnology,
): Omit<MachineConfiguration, 'id'>;
export function createConfiguration(
  machineModelName: WeldingMachineModel,
  weldingTechnology: WeldingTechnology,
  id: string,
): MachineConfiguration;
export function createConfiguration(
  machineModelName: WeldingMachineModel,
  weldingTechnology: WeldingTechnology,
  id?: string,
): Storable<MachineConfiguration> {
  switch (machineModelName) {
    case 'TruArc Weld 1000':
    case 'TruLaser Weld 1000': {
      const technology: WeldingTechnology =
        machineModelName === 'TruArc Weld 1000' ? 'arc' : 'laser';
      const opticalType = weldingTechnology === 'arc' ? 'ARC_WIRE' : 'BEO_D35';

      return {
        id,
        machineModelName,
        weldingTechnology: technology,
        cabinOptions: {
          cabinLayout: 'STANDARD',
          cabinSize: '1200 x 3000',
          robotLinearAxis: MachineConfigPosition.UNSET,
          robotType: 'REGULAR',
        },
        componentOptions: {
          deskRef: { value: MachineConfigDesk.DemellerPlate },
          componentItems: [
            {
              // hint: this tag does NOT match with any translation key (yet, maybe in future if needed):
              tag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.ROT',
              image: '',
              isChecked: true,
              position: MachineConfigPosition.UNSET,
              isEnabled: false,
            },
          ],
        },
        opticNozzleOptions: {
          opticalType,
          nozzleType: MachineConfigNozzleType.Coaxial,
          opticOptions: {
            rotaryModule: false,
            teachLine: false,
            fusionLine: false,
            wire: false,
          },
        },
      };
    }
    case 'TruLaser Weld 5000': {
      return {
        id,
        machineModelName: 'TruLaser Weld 5000',
        weldingTechnology,
        cabinOptions: {
          robotLinearAxis: MachineConfigPosition.LEFT,
          cabinLayout: '4x4',
          cabinSize: '4800 x 4800',
          robotType: 'ION',
        },
        componentOptions: {
          deskRef: { value: MachineConfigDesk.DemellerPlate },
          componentItems: [
            {
              // This constant should match the translation in lang.ts
              tag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DKP',
              image: '../../assets/img/configurator/01_DKP_Dreh-Kipp-Positionierer_Step2.JPG',
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.DT',
              image: '../../assets/img/configurator/02_DT_Drehtisch_Step2.JPG',
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.RW',
              image:
                '../../assets/img/configurator/03_RW_Automatischer_Rotationswechsler_Step2.JPG',
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
            {
              // This constant should match the translation in lang.ts
              tag: 'CONFIGURATOR.DATA.MACHINE_CONFIG_COMPONENT_ITEM.WP',
              image: '../../assets/img/configurator/04_WP_Wendepositionierer_Step2.JPG',
              isChecked: false,
              position: MachineConfigPosition.LEFT,
              isEnabled: true,
            },
          ],
        },
        opticNozzleOptions: {
          opticalType: 'BEO_D70',
          nozzleType: MachineConfigNozzleType.LinearNozzle,
          opticOptions: {
            rotaryModule: true,
            teachLine: false,
            fusionLine: false,
            wire: false,
          },
        },
      };
    }
    default:
      throw new Error(`Unknown machine model "${machineModelName}"`);
  }
}
