/*
  These enum values serve as unique identifier for ui elements such that we can tag
  them while using a usage tracking event such as a click event on an element with
  a`ClickUsageTrackingDirective`.
*/
export enum UiElementIds {
  ABOUT = 'ABOUT',
  CALCULATOR = 'CALCULATOR',
  CALCULATE_EXAMPLE_PART = 'CALCULATE_EXAMPLE_PART',
  CONFIGURATION_PARTS_DOWNLOAD_CAD = 'CONFIGURATION_PARTS_DOWNLOAD_CAD',
  CONFIGURATOR = 'CONFIGURATOR',
  CONFIGURATOR_EDIT = 'CONFIGURATOR_EDIT',
  /*
    TODO: this message will be replaced with the specific CONFIGURATOR_NEW_MACHINE_XXX messages
    once the additional machines feature in configurator is not a preview-feature anymore.
    Remove the CONFIGURATOR_NEW_MACHINE id and all occurrences of it from source code then.
  */
  CONFIGURATOR_NEW_MACHINE = 'CONFIGURATOR_NEW_MACHINE',
  CONFIGURATOR_NEW_MACHINE_TAW1000 = 'CONFIGURATOR_NEW_MACHINE_TAW1000',
  CONFIGURATOR_NEW_MACHINE_TLW1000 = 'CONFIGURATOR_NEW_MACHINE_TLW1000',
  CONFIGURATOR_NEW_MACHINE_TLW5000 = 'CONFIGURATOR_NEW_MACHINE_TLW5000',
  CONFIGURATOR_REMOVE = 'CONFIGURATOR_REMOVE',
  COPYRIGHT = 'COPYRIGHT',
  CREATE_CALCULATION = 'CREATE_CALCULATION',
  CREATE_CALCULATION_FROM_EXAMPLE_PART = 'CREATE_CALCULATION_FROM_EXAMPLE_PART',
  CREATE_CALCULATION_FROM_EXAMPLE_PART_OK = 'CREATE_CALCULATION_FROM_EXAMPLE_PART_OK',
  CREATE_CALCULATION_FROM_EXAMPLE_PART_CANCEL = 'CREATE_CALCULATION_FROM_EXAMPLE_PART_CANCEL',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_DESCRIPTION_BASICS = 'DASHBOARD_DESCRIPTION_BASICS',
  DASHBOARD_DESCRIPTION_CONFIGURATOR = 'DASHBOARD_DESCRIPTION_CONFIGURATOR',
  DASHBOARD_DESCRIPTION_PARTS = 'DASHBOARD_DESCRIPTION_PARTS',
  DASHBOARD_LOGIN = 'DASHBOARD_LOGIN',
  DASHBOARD_LOGIN_BETA = 'DASHBOARD_LOGIN_BETA',
  DASHBOARD_REGISTER = 'DASHBOARD_REGISTER',
  DASHBOARD_NEWS_IMAGE = 'DASHBOARD_NEWS_IMAGE',
  DASHBOARD_NEWS_TITLE = 'DASHBOARD_NEWS_TITLE',
  DASHBOARD_NEWS_DESCRIPTION = 'DASHBOARD_NEWS_DESCRIPTION',
  DASHBOARD_NEWS_PAGINATION = 'DASHBOARD_NEWS_PAGINATION',
  DASHBOARD_FIRST_STEPS = 'DASHBOARD_FIRST_STEPS',
  DASHBOARD_CALCULATION_EDIT = 'DASHBOARD_CALCULATION_EDIT',
  DASHBOARD_CALCULATION_SELECT = 'DASHBOARD_CALCULATION_SELECT',
  DASHBOARD_CALCULATION_NEW = 'DASHBOARD_CALCULATION_NEW',
  IMPRINT = 'IMPRINT',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  PARTS = 'PARTS',
  PARTS_ALL = 'PARTS_ALL',
  PARTS_CATEGORY_ALL = 'PARTS_BUTTON_ALL',
  PARTS_DOWNLOAD = 'PARTS_DOWNLOAD',
  PARTS_FIXTURE_DOWNLOAD = 'PARTS_FIXTURE_DOWNLOAD',
  PARTS_FIXTURE_MORE_DETAILS = 'PARTS_FIXTURE_MORE_DETAILS',
  PARTS_MORE_DETAILS = 'PARTS_MORE_DETAILS',
  PRIVACY = 'PRIVACY',
  SETTINGS = 'SETTINGS',
  UNSUPPORTED_BROWSER_OK = 'UNSUPPORTED_BROWSER_OK',
  WELDING_PRINCIPLES = 'WELDING_PRINCIPLES',
  WELDING_PRINCIPLES_CATEGORY = 'WELDING_PRINCIPLES_CATEGORY',
  WELDING_PRINCIPLES_CATEGORY_DETAILS = 'WELDING_PRINCIPLES_CATEGORY_DETAILS',
  WELDING_PRINCIPLES_MAIL_TO = 'WELDING_PRINCIPLES_MAIL_TO',
  WELDING_PRINCIPLES_MORE_DETAILS = 'WELDING_PRINCIPLES_MORE_DETAILS',
  PARTS_CALCULATION_ENTRY_EDIT = 'PARTS_CALCULATION_ENTRY_EDIT',
  PARTS_CALCULATION_ENTRY_REMOVE = 'PARTS_CALCULATION_ENTRY_REMOVE',
}
