<google-chart
  #chart
  type="LineChart"
  dynamicResize="true"
  [data]="chartData"
  [options]="chartOptions"
  [columns]="chartColumns"
  (ready)="onReady()"
  (select)="onSelect($event)"
>
</google-chart>
