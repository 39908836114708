<xui-menu>
  <xui-menu-tab
    class="dashboard"
    xuiClickUsageTracking="{{ uiElementIds.DASHBOARD }}"
    routerLink="dashboard"
    routerLinkActive="selected"
    iconKey="House"
  ></xui-menu-tab>

  <xui-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.WELDING_PRINCIPLES }}"
    routerLink="welding-principles"
    routerLinkActive="selected"
    iconKey="BendingMachine.Frontal.Bulb.Lit"
  >
    {{ translations.MAIN_MENU.WELDING_PRINCIPLES | translate }}
  </xui-menu-tab>

  <xui-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.PARTS }}"
    routerLink="parts"
    routerLinkActive="selected"
    iconKey="Package"
  >
    {{ translations.MAIN_MENU.PARTS | translate }}
  </xui-menu-tab>

  <xui-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.CONFIGURATOR }}"
    routerLink="configurator"
    routerLinkActive="selected"
    iconKey="Circle.Quartered.Pencil"
  >
    {{ translations.MAIN_MENU.CONFIGURATOR | translate }}
  </xui-menu-tab>

  <xui-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.CALCULATOR }}"
    routerLink="calculator"
    routerLinkActive="selected"
    iconKey="ProductionPlan.Gear"
  >
    {{ translations.MAIN_MENU.CALCULATOR | translate }}
  </xui-menu-tab>

  <xui-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.SETTINGS }}"
    class="move-right language"
    routerLink="/about/settings"
    iconKey="Sphere.CoordinateSystem"
    *ngIf="!hmiService.hideLogin"
  >
    {{ currentLanguageCode }}
  </xui-menu-tab>

  <xui-menu-tab
    xuiClickUsageTracking="{{ uiElementIds.ABOUT }}"
    routerLink="/about"
    routerLinkActive="selected"
    iconKey="More"
    *ngIf="!hmiService.hideLogin"
  ></xui-menu-tab>

  <xui-menu-tab
    class="login"
    xuiClickUsageTracking="{{ uiElementIds.LOGIN }}"
    iconKey="Key"
    *ngIf="!hmiService.hideLogin && !(isLoggedIn$ | async)"
    (click)="login()"
  >
    Login
  </xui-menu-tab>

  <xui-menu-tab
    class="logout"
    xuiClickUsageTracking="{{ uiElementIds.LOGOUT }}"
    iconKey="Door.Open.Arrow.Right"
    *ngIf="!hmiService.hideLogin && (isLoggedIn$ | async)"
    (click)="logout()"
  >
    Logout
  </xui-menu-tab>
  <div class="move-right menu-label" *ngIf="hmiService.hideLogin">weldguide.trumpf.com</div>
</xui-menu>

<div class="app-content" [class.dialog-active]="isDialogActive$ | async">
  <router-outlet></router-outlet>
</div>
