import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TimeUnit, UnitConverter } from '../../../types';
import { ConvertComponent } from '../../components/convert/convert.component';

/**
 * A time-converter that works with the `<lsb-value>` component:
 *
 * ---
 *
 * ~~~html
 * <lsb-value #durationInMinutes [binding]="durationInSeconds">
 *   <lsb-time-converter targetUnit="min"></lsb-time-converter>
 * </lsb-value>
 *
 * <p>Ready in {{ durationInMinutes.value }} minutes.</p>
 * ~~~
 */
@Component({
  selector: 'lsb-time-converter',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeConverterComponent extends ConvertComponent {
  protected knownConverters = [...TimeConverters];
}

// -----------------------------------------
// ---------- Module Internals -------------
// -----------------------------------------

const TimeConverters: UnitConverter<TimeUnit>[] = [
  {
    from: 'sec',
    to: 'sec',
    convert: (sec) => sec,
    convertBack: (sec) => sec,
  },
  {
    from: 'sec',
    to: 'min',
    convert: (sec) => sec / 60,
    convertBack: (min) => min * 60,
  },
  {
    from: 'sec',
    to: 'hours',
    convert: (sec) => sec / 3600,
    convertBack: (hours) => hours * 3600,
  },
];
