import { Injectable } from '@angular/core';
import { LocalStorageService } from '@trumpf/xguide';

@Injectable({
  providedIn: 'root',
})
export class HmiService {
  /**
   * If this parameter set, it means that user can not authorize itself. It means that Login button should not be shown
   * And also it means that each part-detail should be shown to unauthorized users via mechanism which sends to backend special header.
   */
  hideLogin: boolean = false;
  language?: string;
  analytics: boolean = true;

  constructor(private localStorage: LocalStorageService) {
    const hideLoginValue = this.localStorage.get('hmi_hidelogin');
    if (hideLoginValue === 'true') {
      this.hideLogin = true;
    }

    const language = this.localStorage.get('hmi_language_setting');
    if (language) {
      this.language = language;
    }

    const analytics = this.localStorage.get('hmi_analytics');
    if (analytics === 'false') {
      this.analytics = false;
    }
  }
}
