import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransformComponent } from '../../components/transform/transform.component';

/**
 * A value-transformer that works with the `<lsb-value>` component.
 * This component should not be used as it is just a workaround for edge-cases.
 * It works with side-effects and is not considered best practice.
 *
 * @deprecated This component should not be used as it is just a workaround for edge-cases. It works with side-effects and is not considered best practice.
 */
@Component({
  selector: 'lsb-apply-value',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyValueComponent extends TransformComponent {
  @Input() property!: string;
  @Input() target!: any;

  public transform(value: any) {
    if (this.target == null) {
      return;
    }

    const cloned = { ...this.target, [this.property]: value };

    return cloned;
  }

  public transformBack(value: any) {
    throw new Error('Method not supported.');
  }
}
