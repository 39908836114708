export interface ITransform<T> {
  unit?: string;
  transform(value: T | undefined): T | undefined;
  transformBack(value: T | undefined): T | undefined;
}

export interface IValidator<T> {
  canValidate(value: T | undefined): boolean;
  isValid(value: unknown): boolean;
}

export interface IForceUpdateView<T = any> {
  forceUpdateView(value: T): void;
}

export type ConverterFn = (value: number) => number;
export type ConversionDirection = 'to-view' | 'to-data';
