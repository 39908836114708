import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConvertComponent } from './components/convert/convert.component';
import { FormatComponent } from './components/format/format.component';
import { TransformComponent } from './components/transform/transform.component';
import { ValueComponent } from './components/value/value.component';
import { CurrencyConverterComponent } from './converters/currency-converter/currency-converter.component';
import { LengthConverterComponent } from './converters/length-converter/length-converter.component';
import { TimeConverterComponent } from './converters/time-converter/time-converter.component';
import { NumberFormatterComponent } from './formatters/number-formatter/number-formatter.component';
import { ApplyValueComponent } from './utility/apply-value/apply-value.component';
import { ValidateNumberDirective } from './validators/validate-number/validate-number.directive';
import { ValidateRequiredDirective } from './validators/validate-required/validate-required.directive';

const DECLARATIONS = [
  // Base Components
  ValueComponent,
  ConvertComponent,
  FormatComponent,
  TransformComponent,
  // Converters
  CurrencyConverterComponent,
  LengthConverterComponent,
  TimeConverterComponent,
  // Validators
  ValidateNumberDirective,
  ValidateRequiredDirective,
  // Formatters
  NumberFormatterComponent,
  // Utility
  ApplyValueComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: [DECLARATIONS],
  exports: [DECLARATIONS],
})
export class DataTransformModule {}
