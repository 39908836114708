import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CalculatorSettings,
  PartCalculation,
  PartCalculationChartPoint,
  WeldingTechnology,
} from '@shared/types';
import { GoogleChartComponent } from 'angular-google-charts';
import { TranslationHelper } from '../../../shared/helpers';
import { Changes } from '../../../shared/types/changes';
import { CalculationService } from '../../services/calculation/calculation.service';

const stepPercentage = 0.01;

@Component({
  selector: 'lsb-part-calculation-chart',
  templateUrl: './part-calculation-chart.component.html',
  styleUrls: ['./part-calculation-chart.component.scss'],
})
export class PartCalculationChartComponent implements OnChanges {
  private _chartInitialized = false;

  public get xAxisStep(): number {
    return this.currentPartsCount * stepPercentage;
  }

  @Input() currentSettings: CalculatorSettings;

  @Input() calculation: PartCalculation;

  @Input() currentPartsCount: number;

  @Input() method: WeldingTechnology = 'laser';

  @Input() laserLineColor = 'green';
  @Input() arcLineColor = 'blue';

  @Output() selectPoint = new EventEmitter<PartCalculationChartPoint>();

  chartData: number[][] = [];
  chartColumns = ['Parts', 'Laser', 'Arc'];

  chartOptions = {
    colors: [this.laserLineColor, this.arcLineColor],
    is3D: true,
    vAxis: {
      title: '',
    },
    hAxis: {
      title: '',
    },
    crosshair: {
      color: '#000',
      trigger: 'selection',
    },
    legend: { position: 'none' },
    chartArea: { width: '75%', height: '80%' },
  };

  private selectedRowIndex = 0;
  private maxPartsCount = 1;

  @ViewChild('chart', { static: true })
  public chart!: GoogleChartComponent;

  constructor(
    private calculationService: CalculationService,
    public translations: TranslationHelper,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.rerenderChart();
    this.chartOptions.colors = [this.laserLineColor, this.arcLineColor];
  }

  ngOnChanges(changes: Changes<this>) {
    const {
      calculation,
      currentSettings,
      currentPartsCount,
      method,
      arcLineColor,
      laserLineColor,
    } = changes;

    if (calculation || currentSettings || currentPartsCount) {
      this.maxPartsCount = this.currentPartsCount * 2;
      this.rerenderChart();
    }

    if (method) {
      this.resetSelection();
    }

    if (arcLineColor || laserLineColor) {
      this.chartOptions.colors = [this.laserLineColor, this.arcLineColor];
    }
  }

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      const hAxisTitle = this.translateService.instant(this.translations.CALCULATOR.TOTAL_PARTS);
      const vAxisTitle = this.translateService.instant(this.translations.CALCULATOR.TOTAL_COSTS);

      this.chartOptions.hAxis.title = hAxisTitle;
      this.chartOptions.vAxis.title = vAxisTitle;
    });
  }

  public onReady() {
    this._chartInitialized = true;
    this.resetSelection();
  }

  public onSelect(points: any) {
    if (points?.selection === undefined || points.selection.length !== 1) {
      return;
    }

    const selectedTechnology = (points.selection[0].column === 1
      ? 'laser'
      : 'arc') as WeldingTechnology;
    const selectedPartsCount = points.selection[0].row * this.xAxisStep;
    this.selectPoint.emit({
      selectedTechnology: selectedTechnology,
      selectedPartsCount: parseInt(selectedPartsCount as any),
    });
  }

  private resetSelection() {
    if (!this._chartInitialized) {
      return;
    }

    this.chart?.chart?.setSelection([
      { row: this.selectedRowIndex, column: this.method === 'laser' ? 1 : 2 },
    ]);
  }

  private select(row: number): void {
    if (this._chartInitialized) {
      this.chart?.chart?.setSelection([{ row, column: this.method === 'laser' ? 1 : 2 }]);
    }
  }

  private rerenderChart() {
    if (!this.calculation || !this.currentSettings) {
      return;
    }

    const { configuration } = this.calculation;
    const { basics } = configuration;

    this.chartData = [];

    for (let i = 0; i < this.maxPartsCount; i += this.xAxisStep) {
      const partCalculation: PartCalculation = {
        ...this.calculation,
        configuration: {
          ...configuration,
          basics: {
            ...basics,
            totalPieces: i,
          },
        },
      };

      const prices = this.calculationService.calculate(partCalculation);
      this.chartData.push([
        i,
        isNaN(prices.totalCostsLaser) ? 0 : prices.totalCostsLaser,
        isNaN(prices.totalCostsArc) ? 0 : prices.totalCostsArc,
      ]);
    }

    this.selectedRowIndex = 1 / stepPercentage;
    this.select(this.selectedRowIndex);
  }
}
