<ng-container *ngIf="hasMachineConfigurations$ | async; else contentEmpty">
  <ng-container *ngTemplateOutlet="sideBar"></ng-container>

  <div data-ngtx="configurator:content" class="content">
    <ng-container *ngIf="machineConfigurations$ | async as configs">
      <lsb-configurator-list-item
        *ngFor="let machineConfiguration of configs; let index = index"
        [machineConfiguration]="machineConfiguration"
        (edit)="editConfiguration($event)"
        (remove)="removeConfiguration($event)"
        (downloadCad)="downloadCad($event)"
      ></lsb-configurator-list-item>
    </ng-container>
  </div>
</ng-container>

<ng-template #sideBar>
  <div class="left-pane">
    <xui-button
      class="new-machine-button"
      xuiClickUsageTracking="{{ uiElementIds.CONFIGURATOR_NEW_MACHINE }}"
      (click)="showChooseMachineWizard()"
    >
      {{ translations.CONFIGURATOR.CONFIGURE_NEW_MACHINE | translate }}
    </xui-button>
    <div class="button-note">
      {{ translations.CONFIGURATOR.CONFIGURATION_BUTTON_TOOLTIP | translate }}
    </div>
  </div>

  <hr />
</ng-template>

<ng-template #contentEmpty>
  <lsb-create-configuration-view
    (createConfiguration)="createConfigurationForMachine($event)"
    (openConfigurationWizard)="createConfigurationForMachine($event)"
    class="create-view"
    data-ngtx="configurator:content-empty"
  ></lsb-create-configuration-view>
</ng-template>
