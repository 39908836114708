import { Directive, Input } from '@angular/core';
import { ValidatorBase } from '../validator.base';

/**
 * A value-validator that works with the `<lsb-value>` component:
 *
 * ---
 *
 * ~~~html
 * <lsb-value
 *   [(binding)]="name"
 *   lsbValidateRequired
 *   [fallback]="John Doe"
 *   #validated
 * ></lsb-value>
 *
 * <lsb-input-field [(text)]="validated.value"></lsb-input-field>
 * <p> Welcome {{ name }}! </p>
 * ~~~
 */
@Directive({
  selector: '[lsbValidateRequired]',
})
export class ValidateRequiredDirective extends ValidatorBase<any> {
  @Input('lsbRequired') required = true;

  canValidate(): boolean {
    // do not validate, if required is set to false => optional
    return this.required;
  }

  isValid(value: unknown): boolean {
    return value != null && value !== '';
  }
}
