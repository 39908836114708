import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UnitConverter, ValueUnit } from '../../../types';
import { ConversionDirection, ConverterFn, ITransform } from '../../types';
import { TransformComponent } from '../transform/transform.component';

/**
 * Template component for creating custom, number-converter transform-components that work with the `lsb-value` component.
 * In order to use this component just extend from it and implement the abstract methods.
 *
 * ---
 * **Please note:** Converters extending this component will automatically update the unit of the parent's `lsb-value` component
 * to the currently active `targetUnit`. If you want to change this behavior, override the `ngOnChanges` method of this class with
 * your own implementation.
 */
@Component({
  selector: 'lsb-convert',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvertComponent
  extends TransformComponent
  implements ITransform<string>, OnInit, OnChanges, OnDestroy {
  @Input() targetUnit: ValueUnit;

  protected knownConverters: UnitConverter[] = [];

  transform(value: string | undefined): string | undefined {
    return this.doConvert(value, 'to-view');
  }

  transformBack(value: string | undefined): string | undefined {
    return this.doConvert(value, 'to-data');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const unitKey: keyof this = 'targetUnit';

    if (unitKey in changes) {
      this.value.setUnit(this.targetUnit);
    }
  }

  protected getConverter(
    targetUnit: ValueUnit,
    direction: ConversionDirection,
  ): ConverterFn | undefined {
    const converter = this.knownConverters.find((c) => c.to === targetUnit);

    if (converter == null) {
      console.warn(
        `Could not find any suitable converter for target-unit "${targetUnit}" in`,
        this.knownConverters,
      );
    }

    return direction === 'to-view' ? converter?.convert : converter?.convertBack;
  }

  private doConvert(value: string | undefined, direction: ConversionDirection): string | undefined {
    if (value == null) {
      return value;
    }

    const asNumber = parseFloat(value);
    const converter = this.getConverter(this.targetUnit, direction);
    const converted = converter?.(asNumber) ?? asNumber;

    if (isNaN(converted)) {
      throw new Error(`value was not a valid number`);
    }

    return converted.toString();
  }
}
