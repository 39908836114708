import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { DialogHostedView, DialogResult, DialogReturnType } from '@trumpf/xguide';
import { MachineConfiguration } from '../../types';

@Component({
  selector: 'lsb-create-configuration-dialog-view',
  templateUrl: './create-configuration-dialog-view.component.html',
  styleUrls: ['./create-configuration-dialog-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateConfigurationDialogViewComponent
  implements DialogHostedView<void, Maybe<MachineConfiguration>>
{
  closeDialog = new EventEmitter<DialogReturnType<Maybe<MachineConfiguration>>>();

  initDialog(): void {}

  returnWithPayload(config?: MachineConfiguration) {
    this.closeDialog.emit({
      result: DialogResult.Ok,
      payload: config,
    });
  }

  cancel() {
    this.closeDialog.emit({ result: DialogResult.Cancel });
  }
}
