import { DeskRef, MachineConfigComponentItem, MachineConfigDesk } from '../../../types';
import { CabinPlateState } from './cabin-plate-state';
import { OptionRule } from './option-rule';

export function setDefaultValuesForRulesForMiddleSizedCabins(
  dkp: MachineConfigComponentItem,
  dt: MachineConfigComponentItem,
  rw: MachineConfigComponentItem,
  wp: MachineConfigComponentItem,
  desk: DeskRef,
  cabinPlateState: CabinPlateState,
) {
  dkp.isEnabled = false;
  dkp.isChecked = false;
  dkp.position = undefined;

  dt.isEnabled = false;
  dt.isChecked = false;
  dt.position = undefined;

  rw.isEnabled = false;
  rw.isChecked = false;
  rw.position = undefined;

  wp.isEnabled = true;
  wp.isChecked = false;
  wp.position = undefined;

  desk.value = MachineConfigDesk.DemellerPlate;
  cabinPlateState.demellerPlateEnabled = false;
  cabinPlateState.zeroPointClampingSystemEnabled = false;
}

export const RULES_FOR_MIDDLE_SIZED_CABINS: OptionRule[] = [];
