<div class="row">
  <div class="robot-checkbox">
    <xui-checkbox class="option" [(checked)]="parameters.advancedSettings">
      {{ translations.CALCULATOR.CALCULATION_DETAILS.ADVANCED_SETTINGS | translate }}
    </xui-checkbox>
  </div>

  <div *ngIf="weldingTechnology === 'arc'" class="robot-checkbox">
    <xui-checkbox
      class="option"
      [checked]="weldingMethodParameters?.robotWelding || false"
      (checkedChange)="updateRobotWelding($event)"
    >
      <div class="check-box-with-tooltip">
        {{ translations.CALCULATOR.CALCULATION_DETAILS.ROBOT_WELDING | translate }}
        <xui-icon
          class="info-flag robot-welding-tooltip"
          iconKey="Tooltip.Information"
          [size]="IconSize.S"
          [xuiTooltip]="robotWeldingTooltip"
          header-slot
        ></xui-icon>

        <xui-tooltip #robotWeldingTooltip>
          <p class="simple-text-tooltip robot-welding-tooltip">
            {{ translations.CALCULATOR.CALCULATION_DETAILS.TOOLTIP.ROBOT_WELDING | translate }}
          </p>
        </xui-tooltip>
      </div>
    </xui-checkbox>
  </div>
</div>

<div class="wrapper">
  <div class="row">
    <lsb-label [label]="methodSpecificTranslations.WELDING_SPEED | translate">
      <xui-icon
        class="info-flag welding-speed-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="weldingSpeedTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #weldingSpeedTooltip>
        <p class="simple-text-tooltip welding-speed-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.WELDING_SPEED | translate }}
        </p>
      </xui-tooltip>

      <lsb-combobox
        class="welding-speed"
        [class.imperial]="isImperialUnitSystem"
        [valueField]="true"
        [unit]="weldingSpeedInMillimetersPerSecValue.unit"
        [(text)]="weldingSpeedInMillimetersPerSecValue.value"
      >
        <lsb-value
          #weldingSpeedInMillimetersPerSecValue
          lsbValidateRequired
          [lsbValidateNumber]="{ greaterThan: 0 }"
          [binding]="parameters.weldingSpeedInMillimetersPerSec"
          [fallbackValue]="parameters.weldingSpeedInMillimetersPerSec"
          (bindingChange)="update(parameterNames.weldingSpeedInMillimetersPerSec, $event)"
        >
          <lsb-length-converter
            imperialTargetUnit="inch/min"
            metricTargetUnit="m/min"
          ></lsb-length-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>

        <lsb-dropdown-item
          *ngFor="let item of methodSpecificParameters.weldingSpeedInMillimetersPerSec"
          [value]="weldingSpeedInMillimetersPerSecOption.value"
        >
          <lsb-value #weldingSpeedInMillimetersPerSecOption [binding]="item">
            <lsb-length-converter
              imperialTargetUnit="inch/min"
              metricTargetUnit="m/min"
            ></lsb-length-converter>
            <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
          </lsb-value>
        </lsb-dropdown-item>
      </lsb-combobox>
    </lsb-label>

    <lsb-label [label]="methodSpecificTranslations.FIXTURE_COSTS | translate">
      <xui-icon
        class="info-flag fixture-costs-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="fixtureCostsTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #fixtureCostsTooltip>
        <p class="simple-text-tooltip fixture-costs-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.FIXTURE_COSTS | translate }}
        </p>
      </xui-tooltip>

      <lsb-combobox
        [valueField]="true"
        [unit]="'€' | currencyUnit"
        [(text)]="fixtureCostsInEurValue.value"
      >
        <lsb-value
          #fixtureCostsInEurValue
          lsbValidateRequired
          [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
          [binding]="parameters.fixtureCostsInEur"
          [fallbackValue]="parameters.fixtureCostsInEur"
          (bindingChange)="update(parameterNames.fixtureCostsInEur, $event)"
        >
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>

        <lsb-dropdown-item
          *ngFor="let item of methodSpecificParameters.fixtureCostsInEur"
          [value]="fixtureCostsInEurOption.value"
        >
          <lsb-value #fixtureCostsInEurOption [binding]="item">
            <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
          </lsb-value>
        </lsb-dropdown-item>
      </lsb-combobox>
    </lsb-label>
  </div>

  <div class="row">
    <lsb-label [label]="methodSpecificTranslations.TACKING_TIME | translate">
      <xui-icon
        class="info-flag tracking-time-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="trackingTimeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #trackingTimeTooltip>
        <p class="simple-text-tooltip tracking-time-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.TACKING_TIME | translate }}
        </p>
      </xui-tooltip>

      <lsb-combobox
        [valueField]="true"
        [unit]="tackingTimeInSecValue.unit"
        [(text)]="tackingTimeInSecValue.value"
      >
        <lsb-value
          #tackingTimeInSecValue
          lsbValidateRequired
          [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
          [binding]="parameters.tackingTimeInSec"
          [fallbackValue]="parameters.tackingTimeInSec"
          (bindingChange)="update(parameterNames.tackingTimeInSec, $event)"
        >
          <lsb-time-converter targetUnit="sec"></lsb-time-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>

        <lsb-dropdown-item
          *ngFor="let item of methodSpecificParameters.tackingTimeInSec"
          [value]="tackingTimeInSecOption.value"
        >
          <lsb-value #tackingTimeInSecOption [binding]="item">
            <lsb-time-converter targetUnit="sec"></lsb-time-converter>
            <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
          </lsb-value>
        </lsb-dropdown-item>
      </lsb-combobox>
    </lsb-label>

    <lsb-input-field
      class="input-field"
      [label]="methodSpecificTranslations.REWORKING_TIME | translate"
      [valueField]="true"
      [unit]="reworkTimeInSecValue.unit"
      [(text)]="reworkTimeInSecValue.value"
    >
      <lsb-value
        #reworkTimeInSecValue
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
        [binding]="parameters.reworkTimeInSec"
        [fallbackValue]="parameters.reworkTimeInSec"
        (bindingChange)="update(parameterNames.reworkTimeInSec, $event)"
      >
        <lsb-time-converter targetUnit="sec"></lsb-time-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <xui-icon
        class="info-flag reworking-time-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="reworkingTimeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #reworkingTimeTooltip>
        <p class="simple-text-tooltip reworking-time-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.REWORKING_TIME | translate }}
        </p>
      </xui-tooltip>
    </lsb-input-field>
  </div>

  <div class="row" *ngIf="parameters.advancedSettings">
    <lsb-label [label]="methodSpecificTranslations.MEASURE_TIME | translate">
      <xui-icon
        class="info-flag measure-time-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="measureTimeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #measureTimeTooltip>
        <p class="simple-text-tooltip measure-time-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.MEASURE_TIME | translate }}
        </p>
      </xui-tooltip>

      <lsb-combobox
        [label]="methodSpecificTranslations.MEASURE_TIME | translate"
        [valueField]="true"
        [unit]="measureTimeInSecValue.unit"
        [(text)]="measureTimeInSecValue.value"
      >
        <lsb-value
          #measureTimeInSecValue
          lsbValidateRequired
          [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
          [binding]="parameters.measureTimeInSec"
          [fallbackValue]="parameters.measureTimeInSec"
          (bindingChange)="update(parameterNames.measureTimeInSec, $event)"
        >
          <lsb-time-converter targetUnit="sec"></lsb-time-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>

        <lsb-dropdown-item
          *ngFor="let item of methodSpecificParameters.measureTimeInSec"
          [value]="measureTimeInSecOption.value"
        >
          <lsb-value #measureTimeInSecOption [binding]="item">
            <lsb-time-converter targetUnit="sec"></lsb-time-converter>
            <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
          </lsb-value>
        </lsb-dropdown-item>
      </lsb-combobox>
    </lsb-label>

    <lsb-label [label]="methodSpecificTranslations.SETUP_TIME | translate">
      <xui-icon
        class="info-flag setup-time-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="setupTimeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #setupTimeTooltip>
        <p class="simple-text-tooltip setup-time-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.SETUP_TIME | translate }}
        </p>
      </xui-tooltip>

      <lsb-combobox
        [valueField]="true"
        [(text)]="setupTimePerBatchInSecValue.value"
        [unit]="setupTimePerBatchInSecValue.unit"
      >
        <lsb-value
          #setupTimePerBatchInSecValue
          lsbValidateRequired
          [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
          [binding]="parameters.setupTimePerBatchInSec"
          [fallbackValue]="parameters.setupTimePerBatchInSec"
          (bindingChange)="update(parameterNames.setupTimePerBatchInSec, $event)"
        >
          <lsb-time-converter targetUnit="min"></lsb-time-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>

        <lsb-dropdown-item
          *ngFor="let item of methodSpecificParameters.setupTimePerBatchInSec"
          [value]="setupTimePerBatchInSecOption.value"
        >
          <lsb-value #setupTimePerBatchInSecOption [binding]="item">
            <lsb-time-converter targetUnit="min"></lsb-time-converter>
            <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
          </lsb-value>
        </lsb-dropdown-item>
      </lsb-combobox>
    </lsb-label>
  </div>

  <div class="row" *ngIf="parameters.advancedSettings">
    <lsb-input-field
      class="input-field"
      [label]="methodSpecificTranslations.LOADING_TIME | translate"
      [valueField]="true"
      [unit]="loadingTimeInSecValue.unit"
      [(text)]="loadingTimeInSecValue.value"
    >
      <lsb-value
        #loadingTimeInSecValue
        lsbValidateRequired
        [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
        [binding]="parameters.loadingTimeInSec"
        [fallbackValue]="parameters.loadingTimeInSec"
        (bindingChange)="update(parameterNames.loadingTimeInSec, $event)"
      >
        <lsb-time-converter targetUnit="sec"></lsb-time-converter>
        <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
      </lsb-value>

      <xui-icon
        class="info-flag loading-time-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="loadingTimeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #loadingTimeTooltip>
        <p class="simple-text-tooltip loading-time-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.LOADING_TIME | translate }}
        </p>
      </xui-tooltip>
    </lsb-input-field>

    <lsb-label [label]="methodSpecificTranslations.PROGRAMMING_TIME | translate">
      <xui-icon
        class="info-flag programming-time-tooltip"
        iconKey="Tooltip.Information"
        [size]="IconSize.S"
        [xuiTooltip]="programmingTimeTooltip"
        header-slot
      ></xui-icon>

      <xui-tooltip #programmingTimeTooltip>
        <p class="simple-text-tooltip programming-time-tooltip">
          {{ methodSpecificTranslations.TOOLTIPS.PROGRAMMING_TIME | translate }}
        </p>
      </xui-tooltip>

      <lsb-combobox
        [valueField]="true"
        [(text)]="programmingTimeInSecValue.value"
        [unit]="programmingTimeInSecValue.unit"
      >
        <lsb-value
          #programmingTimeInSecValue
          lsbValidateRequired
          [lsbValidateNumber]="{ greaterThanOrEqual: 0 }"
          [binding]="parameters.programmingTimeInSec"
          [fallbackValue]="parameters.programmingTimeInSec"
          (bindingChange)="update(parameterNames.programmingTimeInSec, $event)"
        >
          <lsb-time-converter targetUnit="min"></lsb-time-converter>
          <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
        </lsb-value>

        <lsb-dropdown-item
          *ngFor="let item of methodSpecificParameters.programmingTimeInSec"
          [value]="programmingTimeInSecOption.value"
        >
          <lsb-value #programmingTimeInSecOption [binding]="item">
            <lsb-time-converter targetUnit="min"></lsb-time-converter>
            <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
          </lsb-value>
        </lsb-dropdown-item>
      </lsb-combobox>
    </lsb-label>
  </div>
</div>
