<div class="left-pane">
  <xui-button
    type="primary"
    class="create-button"
    [xuiClickUsageTracking]="uiElementIds.CREATE_CALCULATION"
    (click)="createCalculation()"
  >
    {{ translations.CALCULATOR.CREATE_CALCULATION | translate }}
  </xui-button>
  <xui-button
    class="create-button"
    type="secondary"
    [xuiClickUsageTracking]="uiElementIds.CREATE_CALCULATION_FROM_EXAMPLE_PART"
    (click)="createCalculationFromExampleParts()"
  >
    {{ translations.CALCULATOR.CREATE_FROM_EXAMPLE_PART | translate }}
  </xui-button>

  <div class="additional-content">
    <lsb-file-drop-zone
      *ngIf="flags.enablePreviewFeatures$ | async"
      accept=".stp,.step"
      class="file-drop"
      clickable
      (fileDrop)="createFromStepModel($event)"
    >
      {{ translations.CALCULATOR.DROP_STEP_FILE_HINT | translate }}
    </lsb-file-drop-zone>

    <div class="button-note">
      {{ translations.CALCULATOR.CREATE_CALCULATION_HINT | translate }}
    </div>

    <lsb-calculation-settings-sidebar
      [settings]="currentSettings$ | async"
      (settingsChange)="saveSettings($event)"
      class="settings-bar"
    ></lsb-calculation-settings-sidebar>
  </div>
</div>

<hr />

<div class="content">
  <ng-container *ngIf="hasResults$ | async; else contentEmpty">
    <lsb-part-calculation-table
      [calculationRows]="calculationResults$"
      [selected]="selected$ | async"
      (edit)="editCalculation($event)"
      (delete)="deleteCalculation($event)"
      (calculationChange)="updateCalculation($event.calculation, $event.updatedField)"
      (selectionChange)="selection.next($event)"
      class="results"
    ></lsb-part-calculation-table>

    <lsb-calculation-details
      class="details"
      [calculationResult]="selected$"
      [currentSettings]="currentSettings$"
      (calculationChange)="updateCalculation($event.calculation, $event.updatedField)"
      (previewClick)="editCalculation($event)"
    ></lsb-calculation-details>
  </ng-container>
</div>

<ng-template #contentEmpty>
  <div class="empty-content">
    {{ translations.CALCULATOR.CREATE_CALCULATION_HINT | translate }}
  </div>
</ng-template>
