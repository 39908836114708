<div class="output-text h1">
  <span class="output-headline">{{ translations.DATA_PRIVACY.TITLE | translate }}</span>
</div>

<div class="output copytext content">
  <span class="output-content">
    {{ translations.DATA_PRIVACY.PARAGRAPH1 | translate }}
  </span>
  <span class="output-content">
    {{ translations.DATA_PRIVACY.PARAGRAPH2 | translate }}
  </span>
  <span class="output-content">
    {{ translations.DATA_PRIVACY.PARAGRAPH3 | translate }}

    <a [href]="translations.DATA_PRIVACY.LINK_URL | translate" target="_blank">
      {{ translations.DATA_PRIVACY.LINK_TITLE | translate }}
    </a>

    {{ translations.DATA_PRIVACY.AFTER_LINK_TEXT | translate }}
  </span>
  <span class="output-content link" (click)="showPrivacySettings()">
    {{ translations.XGUIDE.PRIVACY.TITLE | translate }}
  </span>
</div>
