import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormatComponent } from '../../components/format/format.component';

/**
 * A number-formatter that works with the `<lsb-value>` component:
 *
 * ---
 *
 * ~~~html
 * <lsb-value #priceWithWithOnlyTwoDecimals [binding]="priceWithManyDecimals">
 *   <lsb-number-formatter [maxDecimals]="2"></lsb-number-formatter>
 * </lsb-value>
 *
 * <p>Price: {{ priceWithWithOnlyTwoDecimals.value }}€ </p>
 * ~~~
 */
@Component({
  selector: 'lsb-number-formatter',
  template: '',
  styles: [':host { display: none !important; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberFormatterComponent extends FormatComponent {
  @Input() maxDecimals = 2;
  @Input() unit?: string;

  public transform(value: string | undefined): string | undefined {
    if (value == null) {
      return value;
    }

    const fixedDecimals = parseFloat(value).toFixed(this.maxDecimals);

    return this.unit ? `${fixedDecimals} ${this.unit}` : fixedDecimals;
  }
}
